import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { MeasuresService } from './services/measures/measures.service';
import { StorageService } from './services/storage/storage.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ApiService } from 'src/app/services/api/api.service';
import { ScenariosService } from './services/scenarios/scenarios.service';
import { SourcesService } from 'src/app/services/catalogs/sources.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import jwt_decode from "jwt-decode";
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { extend } from 'jquery';
import { Scenario } from './models/Scenario';
import { Source } from './models/Source';
import { OperationsService } from './services/operations/operations.service';

interface IPPELoginModel {
  applicationId : string;
  password: string;
  username: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent  {

  private source :string;
  scenario: any;

  source1: Source = {
    id: 0,
    name: "",
    pctSeContent: 0.0,
    pctAvailableSeMet: 0.00,
    costLb: 0.05,
    seleniumInclusion: {
      puritySe: 0.0,
      pctAmountSe: 0.0,
      pctAvailableSeMet: 0.0,
      additionRateSeKgMT: 0.0,
      additionRateSegMT: 0.0,
      additionRateSeKgST: 0.0,
      additionRateSeLbST: 0.0
    },
    costSource: {
      costKg: 0.0,
      costKgMt: 0.0,
      costLb: 0.0,
      costLbSt: 0.0
    },
    annualFeedAndSourceTonnage: {
      feedVolMt: 0.0,
      potSeVolKg: 0.0,
      potSeVolMt: 0.0,
      potSeVolLbs: 0.0,
      potSeVolSt: 0.0,
      costUsdLb: 0.0,
      costUsdMt: 0.0,
      costUsdShorTon: 0.0,
      costUsdMetricTon: 0.0,
      costKgMt: 0.0,
      costLbSt: 0.0
    },
    quaterlyFeedAndSourceTonnage: {
      feedVolSt: 0.0,
      feedVolMt: 0.0,
      potSeVolKg: 0.0,
      potSeVolMt: 0.0,
      potSeVolLbs: 0.0,
      potSeVolSt: 0.0,
      costUsdLb: 0.0,
      costUsdMt: 0.0,
      costKgMt: 0.0,
      costLbSt: 0.0
    }
  }
  currentScenario: Scenario = {
    name: '',
    companyId: 0,
    contactId: 0,
    date: new Date,
    description: '',
    comments: '',
    measurementSystemChar:'',
    dataSource: {
      source1: this.source1,
      source2: this.source1,
      source3: this.source1,
      amountRequiredDiet: 0.000,
      feedVolSt: 0,
      measure: {
        id: 'i',
        name: 'imperial'
      },
    },

  }

  constructor(
    private platform: Platform,
    private navController: NavController,
    private storageService: StorageService,
    private _measures: MeasuresService,
    private screenOrientation: ScreenOrientation,
    private _sources: SourcesService,
    public _auth: AuthService,
    private _api: ApiService,
    private _operations: OperationsService,
    private _scenarios: ScenariosService,
    private loadingController: LoadingController,
  ) 
  {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      this.storageService.getItem('token').subscribe((user)=> {

        //Get urlParameter
        

        // if(this.platform.is('mobileweb') || this.platform.is('desktop'))
        // {
          this.getParameter();
          this.storageService.setItem('param', this.source);
        // }

        if(this.source == "kiosk")
        {

        var user = this.storageService.getItem('user').value

        if(!user)
          {        
         // Authenticate 
          var loginModel: IPPELoginModel =  
          {
            applicationId: "C1F3A7D7-709D-EB11-A008-00155E00F00B",
            username: "IPPEAdisseo",
            password: "ipAD#2022"
          };

          this._auth.login(loginModel).then(token => {
            let user:any = jwt_decode(token);
            user.token = token;
            this.storageService.setItem('user',user);
            this.loadScenario().then(() => {

            //Load scenario form
            this.navController.navigateRoot('scenario');
            })
          }).catch(err => {
            console.log(err);
          })
        } else {
          this.loadScenario().then(() => {

            //Load scenario form
            this.navController.navigateRoot('scenario');
          })
        }
    
         }

          else if(user) {
      
          if(this._measures.getSelectedMeasure().id==null) {
            this._measures.selectMeasure('i');
          }
          this.navController.navigateRoot('home');
        } else {

          this.navController.navigateRoot('login');
          if(this._measures.getSelectedMeasure().id==null) {
            this._measures.selectMeasure('i');
          }
        }
      });
    });
  }

  getParameter() {
    if (document.URL.indexOf("?") > 0) {
      let splitURL = document.URL.split("?");
      let splitParams = splitURL[1].split("&");
      let i: any;
      for (i in splitParams){
        let singleURLParam = splitParams[i].split('=');
        if (singleURLParam[0] == "utm_source"){
          this.source = singleURLParam[1];
        }
      }
    }
  }

  async loadScenario(){
    //Get scenario 
    const res = await this._api.get('scenario/get',{
      rowsPerPage: 1,
    })

    await this.setScenario(res.scenarioList.items[0]);

    //Load sources
    this._sources.fetchSources()
}
    
  async setScenario(scenario) {
    let loading  = await this.loadingController.create({
      message:'Loading data...',
      spinner:'dots',
      mode:'ios'
    });
    loading.present();
    try {
      if(scenario.source1!=null && scenario.source2!=null && scenario.source3!=null) {
        const res1 = await this._api.get('source/get',{sourceId: scenario.source1.sourceId})
        let source1 = res1.items;
        const res2 = await this._api.get('source/get',{sourceId: scenario.source2.sourceId})
        let source2 = res2.items;
        const res3 = await this._api.get('source/get',{sourceId: scenario.source3.sourceId})
        let source3 = res3.items;
        setTimeout(async ()=> {
          let setScenario = {
            createdBy   : scenario.createdBy,
            companyId   : scenario.companyViewModel,
            contactId   : scenario.contactViewModel,
            date        : scenario.creationDate,
            comments    : scenario.comments,
            description : scenario.description,
            name        : scenario.name,
            optionOnePercent: scenario.optionOnePercent,
            measurementSystemChar : scenario.measurementSystemChar,
            scenarioId  : scenario.scenarioId,
            dataSource  : {
              amountRequiredDiet: scenario.amountRequiredDiet,
              measure: scenario.measurementSystem,
              feedVolSt: scenario.feedVolSt,
              source1: {
                sourceId:scenario.source1.sourceId,
                name:source1[0].name,
                id: source1[0].id,
                costLb: scenario.source1.cost,
                pctAvailableSeMet: scenario.source1.pctAvailableSeMet,
                pctSeContent: scenario.source1.pctSeContent
              },
              source2: {
                sourceId:scenario.source2.sourceId,
                name:source2[0].name,
                id: source2[0].id,
                costLb: scenario.source2.cost,
                pctAvailableSeMet: scenario.source2.pctAvailableSeMet,
                pctSeContent: scenario.source2.pctSeContent
              },
              source3: {
                sourceId:scenario.source3.sourceId,
                name:source3[0].name,
                id: source3[0].id,
                costLb: scenario.source3.cost,
                pctAvailableSeMet: scenario.source3.pctAvailableSeMet,
                pctSeContent: scenario.source3.pctSeContent
              }
            }
          }
          this.rebuildScenario(setScenario).then(()=> {
            localStorage.setItem('newScenario','false');
            this.navController.navigateRoot('scenario');
            loading.dismiss();
          });
        },1000);
      } 
    } catch (e) {
      // this.utils.showAlert('The selected scenario contains corrupted data, please contact support...');
      loading.dismiss();
    }
  }

  
  async rebuildScenario(scenario:any) {
    this.currentScenario = scenario;
    const source1: Source = {
      sourceId            : scenario.dataSource.source1.sourceId,
      id                  : scenario.dataSource.source1.id,
      name                : scenario.dataSource.source1.name,
      pctSeContent        : scenario.dataSource.source1.pctSeContent,
      pctAvailableSeMet   : scenario.dataSource.source1.pctAvailableSeMet,
      costLb              : scenario.dataSource.source1.costLb
    }
    const source2: Source = {
      sourceId            : scenario.dataSource.source2.sourceId,
      id                  : scenario.dataSource.source2.id,
      name                : scenario.dataSource.source2.name,
      pctSeContent        : scenario.dataSource.source2.pctSeContent,
      pctAvailableSeMet   : scenario.dataSource.source2.pctAvailableSeMet,
      costLb              : scenario.dataSource.source2.costLb
    }

    const source3: Source = {
      sourceId            : scenario.dataSource.source3.sourceId,
      id                  : scenario.dataSource.source3.id,
      name                : scenario.dataSource.source3.name,
      pctSeContent        : scenario.dataSource.source3.pctSeContent,
      pctAvailableSeMet   : scenario.dataSource.source3.pctAvailableSeMet,
      costLb              : scenario.dataSource.source3.costLb
    }

    //source 1: Inorganic
    //source 2: Selisseo
    //source 3: Seleno

    const amountRequiredDiet = (scenario.dataSource.amountRequiredDiet * scenario.optionOnePercent / 100)
    const datasource = {
      source1: this._operations.calculateSource(source1,scenario.dataSource.amountRequiredDiet , scenario.dataSource.feedVolSt),
      source2: this._operations.calculateSource(source2, scenario.dataSource.amountRequiredDiet, scenario.dataSource.feedVolSt),
      source3: this._operations.calculateSource(source3, scenario.dataSource.amountRequiredDiet, scenario.dataSource.feedVolSt),

      halfSource1: null,
      halfSource2: null,
      halfSource3: null,

      equivalentHalfSource1: null,
      equivalentHalfSource2: null,

      amountRequiredDiet: scenario.dataSource.amountRequiredDiet,
      feedVolSt: scenario.dataSource.feedVolSt,
      measure: scenario.dataSource.measure
    }
    datasource.halfSource1 = this._operations.calculateSource(source1, (scenario.dataSource.amountRequiredDiet - amountRequiredDiet), scenario.dataSource.feedVolSt)
    datasource.halfSource2 = this._operations.calculateSource(source2, amountRequiredDiet, scenario.dataSource.feedVolSt)
    datasource.halfSource3 = this._operations.calculateSource(source3, amountRequiredDiet, scenario.dataSource.feedVolSt)

    const equivalentSelisseoFromSelenoYeast = (source3.pctAvailableSeMet / 100) * datasource.halfSource3.amountRequiredDiet
    datasource.equivalentHalfSource2 = this._operations.calculateSource(source2, equivalentSelisseoFromSelenoYeast   , scenario.dataSource.feedVolSt)

    datasource.equivalentHalfSource1 = this._operations.calculateSource(source1,  scenario.dataSource.amountRequiredDiet - equivalentSelisseoFromSelenoYeast , scenario.dataSource.feedVolSt)

    this.currentScenario.dataSource = datasource;
    this.calculate(this.currentScenario);
    this.storageService.setItem('scenario',this.currentScenario);
    this._scenarios.setScenario(this.currentScenario);
  }
  async calculate(currentScenario: Scenario) {
    this.currentScenario = this._operations.calculateScenario(this.currentScenario);
    const scenario = await this._scenarios.setScenario(this.currentScenario);
  }

  }



