import { EditScenarioComponent } from './../edit-scenario/edit-scenario.component';
import { LoadingController, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { SourcesService } from 'src/app/services/catalogs/sources.service';
import { MiscService } from 'src/app/services/misc/misc.service';
import { MeasuresService } from 'src/app/services/measures/measures.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OperationsService } from 'src/app/services/operations/operations.service';
import { Session, SessionService } from 'src/app/services/sessions/session.service';
import { Source } from 'src/app/models/Source';
import { Measure } from 'src/app/models/Measure';
import { Scenario } from 'src/app/models/Scenario';
import { ScenariosService } from 'src/app/services/scenarios/scenarios.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-set-comparison',
  templateUrl: './set-comparison.component.html',
  styleUrls: ['./set-comparison.component.scss'],
})
export class SetComparisonComponent implements OnInit {
  form: FormGroup;
  currentSession: Session = null;
  sources: Source[] = [];
  sourcesInorganic: Source[] = [];
  sourcesSelisseo: Source[] = [];
  sourcesSelenoYeast: Source[] = [];
  measures: Measure[] = [];
  currentScenario: Scenario = null;
  source1: Source = null;
  source2: Source = null;
  source3: Source = null;
  titleVolumeFeed = 'Feed volume ST';
  currentFeed = 0.0000;
  feedLb = 0.0000;
  isFirstTime = true;
  public measureSelected: Measure = {
    id: 'i',
    name: 'Imperial'
  };
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    public currentMisc: MiscService,
    private utils: UtilsService,
    private loadingController: LoadingController,
    private currentApi: ApiService,
    public currentSources: SourcesService,
    public currentMeasures: MeasuresService,
    public currentScenarios: ScenariosService,
    public currentOperations: OperationsService,
    public currentSessions: SessionService,
    public currentStorage: StorageService
  ) {
    this.form = fb.group({
      source1: [{ value: '', disabled: false }, [Validators.required]],
      pctSeContentS1: [{ value: '0.0', disabled: false }, [Validators.required]],
      pctAvailableSeMetS1: [{ value: '0.0', disabled: false }, [Validators.required]],
      costLbS1: [{ value: '0.0', disabled: false }, [Validators.required]],
      source2: [{ value: '', disabled: false }, [Validators.required]],
      pctSeContentS2: [{ value: '0.0', disabled: false }, [Validators.required]],
      pctAvailableSeMetS2: [{ value: '0.0', disabled: false }, [Validators.required]],
      costLbS2: [{ value: '0.0', disabled: false }, [Validators.required]],
      source3: [{ value: '', disabled: false }, [Validators.required]],
      pctSeContentS3: [{ value: '0.0', disabled: false }, [Validators.required]],
      pctAvailableSeMetS3: [{ value: '0.0', disabled: false }, [Validators.required]],
      costLbS3: [{ value: '0.0', disabled: false }, [Validators.required]],
      amount: [{ value: '0.3', disabled: false }, [Validators.required, Validators.min(0.0001), Validators.max(0.3)]],
      feed: [{ value: '0.0', disabled: false }, [Validators.required]],
      optionOnePercent: [{ value: '50.00', disabled: false }, [Validators.required, Validators.max(100), Validators.min(.01)]],
      measure: ['', Validators.required]
    });
    this.currentStorage.getItem('selisseo_measure').subscribe((res: any) => {
      this.measureSelected = res;
      this.form.get('measure').setValue(res.id);
      setTimeout(() => {
        // this.recalculateByTypeMetricSystem()
      }, 500);
    });
  }

  get setComparisonForm() {
    return this.form.controls;
  }

  setMetricSystem(event) {
    this.currentMeasures.selectMeasure(event.value);
    this.measureSelected = this.currentMeasures.currentMeasure;
    this.form.get('measure').setValue(this.measureSelected.id);
    this.recalculateByTypeMetricSystem();
    if (this.measureSelected.id === 'i') {
      this.titleVolumeFeed = 'Feed volume ' + 'ST';
    } else {
      this.titleVolumeFeed = 'Feed volume ' + 'MT';
    }
  }
  feedChanged(event) {
    if (this.measureSelected.id === 'i' && this.feedLb !== event) {
      this.feedLb = this.currentFeed;
    } else if (this.measureSelected.id === 'm' && this.currentOperations.feedVolMT(this.feedLb, 1) !== event) {
      // need formula from feedVOLMT -> feedVOLST .. used a rule of three in the meantime..
      this.feedLb = this.currentFeed / .9071856666666667;
    }
  }

  ngOnInit() {

    this.currentSessions.session$.subscribe(session => {
      this.currentSession = session;
    });

    //Default Sytem Metric
    this.measureSelected = this.currentMeasures.getSelectedMeasure();
    this.setSources();



    this.measures = this.currentMeasures.measures;
    this.currentScenarios.scenario$.subscribe(scenario => {
      this.currentScenario = scenario;

      if (this.currentScenario) {

        this.setComparisonForm.optionOnePercent.setValue(this.currentScenario.optionOnePercent);
        if (this.currentScenario.companyId.companyKey === 1037 && !this.currentScenario.dataSource) {
          //defaults for adisseo NA requested by Felipe Navarro..
          if (!this.sources) { return; }

          this.setSource1Values(this.sources.find(source => source.id === 3), false);
          this.setSource2Values(this.sources.find(source => source.id === 2), false);
          this.setSource3Values(this.sources.find(source => source.id === 10), false);
          const amountRequiredDiet = 0.3;
          this.form.controls.amount.setValue(amountRequiredDiet);
          this.currentFeed = 1000;
          this.setComparisonForm.feed.setValue(this.currentFeed);

        }
        else if (this.currentScenario.dataSource?.source1?.id !== 0 && this.currentScenario.dataSource?.source1?.id !== null) {

          this.measureSelected = this.currentMeasures.measures.find(m => m.id === this.currentScenario.measurementSystemChar);
          this.form.get('measure').setValue(this.measureSelected.id);
          if (this.measureSelected.id === 'i') {
            this.currentFeed = this.currentScenario.dataSource.feedVolSt;
            this.feedLb = this.currentFeed;
          }
          else {
            this.feedLb = this.currentScenario.dataSource.feedVolSt;
            this.currentFeed = this.currentOperations.feedVolMT(this.currentScenario.dataSource.feedVolSt, 1);
          }


          this.form.controls.amount.setValue(this.currentScenario.dataSource.amountRequiredDiet);

          this.setSource1Values(this.currentScenario.dataSource.source1, false);
          this.form.controls.source1.setValue(this.sources.find(source => source.id === this.currentScenario.dataSource.source1.id), {
            onlySelf: true,
          });

          this.setSource2Values(this.currentScenario.dataSource.source2, false);
          this.form.controls.source2.setValue(this.sources.find(source => source.id === this.currentScenario.dataSource.source2.id), {
            onlySelf: true,
          });

          this.setSource3Values(this.currentScenario.dataSource.source3, false);
          this.form.controls.source3.setValue(this.sources.find(source => source.id === this.currentScenario.dataSource.source3.id), {
            onlySelf: true,
          });

        }
        else {


          this.setSource1Values(this.sources.find(source => source.id === 3), false);
          this.setSource2Values(this.sources.find(source => source.id === 2), false);
          this.setSource3Values(this.sources.find(source => source.id === 10), false);
          this.currentFeed = 1000;
          this.feedLb = this.currentOperations.feedVolMT(this.currentFeed, 1);
          const amountRequiredDiet = 0.3;
          this.form.controls.amount.setValue(amountRequiredDiet);
          if (this.measureSelected.id === 'm') { }

        }


      }


    });
  }

  setSource1Values(item, useValue = true) {

    if (useValue) {
      this.source1 = item.value;
    }
    else {
      this.source1 = item;
      this.setComparisonForm.source1.setValue(item);
    }
    if (this.measureSelected.id === 'm') {
      this.setComparisonForm.pctSeContentS1.setValue(this.source1.pctSeContent);
      this.setComparisonForm.pctAvailableSeMetS1.setValue(this.source1.pctAvailableSeMet);
      this.setComparisonForm.costLbS1.setValue(this.source1.costLb * this.currentOperations.lbsByKg);

    } else {

      this.setComparisonForm.pctSeContentS1.setValue(this.source1.pctSeContent);
      this.setComparisonForm.pctAvailableSeMetS1.setValue(this.source1.pctAvailableSeMet);
      this.setComparisonForm.costLbS1.setValue(this.source1.costLb);
    }
  }

  setSource2Values(item, useValue = true) {
    if (useValue) {
      this.source2 = item.value;
    }
    else {
      this.source2 = item;
      this.setComparisonForm.source2.setValue(item);
    }
    if (this.measureSelected.id === 'm') {
      this.setComparisonForm.pctSeContentS2.setValue(this.source2.pctSeContent);
      this.setComparisonForm.pctAvailableSeMetS2.setValue(this.source2.pctAvailableSeMet);
      this.setComparisonForm.costLbS2.setValue(this.source2.costLb * this.currentOperations.lbsByKg);
    } else {
      this.setComparisonForm.pctSeContentS2.setValue(this.source2.pctSeContent);
      this.setComparisonForm.pctAvailableSeMetS2.setValue(this.source2.pctAvailableSeMet);
      this.setComparisonForm.costLbS2.setValue(this.source2.costLb);
    }
  }

  setSource3Values(item, useValue = true) {
    if (useValue) {
      this.source3 = item.value;
    }
    else {
      this.source3 = item;
      this.setComparisonForm.source3.setValue(item);
    }
    this.setComparisonForm.pctSeContentS3.setValue(this.source3.pctSeContent);
    this.setComparisonForm.pctAvailableSeMetS3.setValue(this.source3.pctAvailableSeMet);
    if (this.measureSelected.id === 'm') {
      this.setComparisonForm.costLbS3.setValue(this.source3.costLb * this.currentOperations.lbsByKg);
    } else {
      this.setComparisonForm.costLbS3.setValue(this.source3.costLb);
    }
  }

  recalculateByTypeMetricSystem() {

    if (this.measureSelected.id === 'm') {

      this.setComparisonForm.costLbS1.setValue(this.setComparisonForm.costLbS1.value * this.currentOperations.lbsByKg);
      this.setComparisonForm.costLbS2.setValue(this.setComparisonForm.costLbS2.value * this.currentOperations.lbsByKg);
      this.setComparisonForm.costLbS3.setValue(this.setComparisonForm.costLbS3.value * this.currentOperations.lbsByKg);
      this.currentFeed = this.currentOperations.feedVolMT(this.currentFeed, 1);

    } else if (this.measureSelected.id === 'i') {

      this.setComparisonForm.costLbS1.setValue(this.setComparisonForm.costLbS1.value / this.currentOperations.lbsByKg);
      this.setComparisonForm.costLbS2.setValue(this.setComparisonForm.costLbS2.value / this.currentOperations.lbsByKg);
      this.setComparisonForm.costLbS3.setValue(this.setComparisonForm.costLbS3.value / this.currentOperations.lbsByKg);
      this.currentFeed = this.feedLb;
    }

  }

  async setScenario() {


    const costLb1 = this.measureSelected.id === 'i'
      ? this.setComparisonForm.costLbS1.value
      : this.setComparisonForm.costLbS1.value / this.currentOperations.lbsByKg;
    const costLb2 = this.measureSelected.id === 'i'
      ? this.setComparisonForm.costLbS2.value
      : this.setComparisonForm.costLbS2.value / this.currentOperations.lbsByKg;
    const costLb3 = this.measureSelected.id === 'i'
      ? this.setComparisonForm.costLbS3.value
      : this.setComparisonForm.costLbS3.value / this.currentOperations.lbsByKg;



    const source1: Source = {
      sourceId: this.setComparisonForm.source1.value.sourceId,
      id: this.setComparisonForm.source1.value.id,
      name: this.setComparisonForm.source1.value.name,
      pctSeContent: this.setComparisonForm.pctSeContentS1.value,
      pctAvailableSeMet: this.setComparisonForm.pctAvailableSeMetS1.value,
      costLb: costLb1
    };
    const source2: Source = {
      sourceId: this.setComparisonForm.source2.value.sourceId,
      id: this.setComparisonForm.source2.value.id,
      name: this.setComparisonForm.source2.value.name,
      pctSeContent: this.setComparisonForm.pctSeContentS2.value,
      pctAvailableSeMet: this.setComparisonForm.pctAvailableSeMetS2.value,
      costLb: costLb2
    };

    const source3: Source = {
      sourceId: this.setComparisonForm.source3.value.sourceId,
      id: this.setComparisonForm.source3.value.id,
      name: this.setComparisonForm.source3.value.name,
      pctSeContent: this.setComparisonForm.pctSeContentS3.value,
      pctAvailableSeMet: this.setComparisonForm.pctAvailableSeMetS3.value,
      costLb: costLb3
    };

    const amountRequiredDiet = (this.setComparisonForm.amount.value * this.setComparisonForm.optionOnePercent.value / 100);
    const datasource = {
      source1: this.currentOperations.calculateSource(
        source1,
        this.setComparisonForm.amount.value,
        this.feedLb),
      source2: this.currentOperations.calculateSource(
        source2,
        this.setComparisonForm.amount.value,
        this.feedLb),
      source3: this.currentOperations.calculateSource(
        source3,
        this.setComparisonForm.amount.value,
        this.feedLb),

      halfSource1: null,
      halfSource2: null,
      halfSource3: null,

      equivalentHalfSource1: null,
      equivalentHalfSource2: null,


      amountRequiredDiet: this.setComparisonForm.amount.value,
      feedVolSt: this.feedLb,
      measure: this.measureSelected
    };
    datasource.halfSource1 = this.currentOperations.calculateSource(
      source1,
      (this.setComparisonForm.amount.value - amountRequiredDiet),
      this.feedLb
    );
    datasource.halfSource2 = this.currentOperations.calculateSource(
      source2,
      amountRequiredDiet,
      this.feedLb
    );
    datasource.halfSource3 = this.currentOperations.calculateSource(
      source3,
      amountRequiredDiet,
      this.feedLb
    );

    const equivalentSelisseoFromSelenoYeast = (source3.pctAvailableSeMet / 100) * datasource.halfSource3.amountRequiredDiet;
    datasource.equivalentHalfSource2 = this.currentOperations.calculateSource(
      source2,
      equivalentSelisseoFromSelenoYeast,
      this.feedLb);


    datasource.equivalentHalfSource1 = this.currentOperations.calculateSource(
      source1,
      this.setComparisonForm.amount.value - equivalentSelisseoFromSelenoYeast,
      this.feedLb);

    this.currentScenario.measurementSystemChar = this.measureSelected.id;
    this.currentScenario.optionOnePercent = this.setComparisonForm.optionOnePercent.value;
    this.currentScenario.dataSource = datasource;
    // this.saveChanges();
    this.calculate(this.currentScenario);
    this.dismiss();
    /*
        const scenario = await this._scenarios.setScenario(this.currentScenario);
        if (scenario) {
          this.dismiss();
        } */

  }

  async saveChanges() {
    const scenario = {
      scenarioId: this.currentScenario.scenarioId,
      contactId: this.currentScenario.contactId.contactId,
      companyId: this.currentScenario.companyId.companyId,
      name: this.currentScenario.name,
      description: this.currentScenario.description,
      amountRequiredDiet: this.currentScenario.dataSource.amountRequiredDiet,
      feedVolSt: this.currentScenario.dataSource.feedVolSt,
      measurementSystemChar: this.currentScenario.measurementSystemChar,
      optionOnePercent: this.currentScenario.optionOnePercent,
      costTableType: [
        {
          sourceId: this.currentScenario.dataSource.source1.sourceId,
          pctSeContent: this.currentScenario.dataSource.source1.pctSeContent,
          pctAvailableSeMet: this.currentScenario.dataSource.source1.pctAvailableSeMet,
          cost: this.currentScenario.dataSource.source1.costLb
        },
        {
          sourceId: this.currentScenario.dataSource.source2.sourceId,
          pctSeContent: this.currentScenario.dataSource.source2.pctSeContent,
          pctAvailableSeMet: this.currentScenario.dataSource.source2.pctAvailableSeMet,
          cost: this.currentScenario.dataSource.source2.costLb
        },
        {
          sourceId: this.currentScenario.dataSource.source3.sourceId,
          pctSeContent: this.currentScenario.dataSource.source3.pctSeContent,
          pctAvailableSeMet: this.currentScenario.dataSource.source3.pctAvailableSeMet,
          cost: this.currentScenario.dataSource.source3.costLb
        }
      ]
    };

    this.currentApi.post('scenario/InsertOrUpdate', scenario)
      .then((res) => {

      })
      .finally();
  }

  async calculate(currentScenario: Scenario) {
    this.currentScenario = this.currentOperations.calculateScenario(this.currentScenario);
    const scenario = await this.currentScenarios.setScenario(this.currentScenario);
    if (scenario) {
      // this.router.navigateByUrl('scenario')
      this.dismiss();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true
    });
  }

  async editScanerio() {
    this.currentStorage.setItem('editScenario', this.currentScenario);
    this.currentStorage.setItem('currentCompany', this.currentScenario.companyId);
    this.currentStorage.setItem('currentContact', this.currentScenario.contactId);
    const modal = await this.modalCtrl.create({
      component: EditScenarioComponent,
      cssClass: 'modal-new-scenario modal-large',
      animated: true
    });
    modal.onDidDismiss().then((data: any) => {
      if (data) {
        this.currentSession = data;
      }
    });
    return await modal.present();
  }
  private setSources() {
    this.sources = this.currentSources.getSources();
    this.sourcesInorganic = this.sources.filter(x => x.sourceTypeKey === 1);
    this.sourcesSelisseo = this.sources.filter(x => x.sourceTypeKey === 2);
    this.sourcesSelenoYeast = this.sources.filter(x => x.sourceTypeKey === 3);
  }
}
