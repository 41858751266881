import { LoadingController, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Company } from 'src/app/models/Company';
import { StorageService } from 'src/app/services/storage/storage.service';


@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss'],
})
export class NewContactComponent implements OnInit {

  currentSession;
  company:Company;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private api: ApiService,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.form = this.fb.group({
      companyId       : [(this.company!=null?this.company.companyId:0), [Validators.required]],
      titleName       : ['', Validators.required],
      firstName       : ['', Validators.required],
      lastName        : ['', Validators.required],
      email           : ['', [Validators.required, Validators.email]],
      phone           : ['', Validators.required],
      extension       : [''],
      cellPhone       : ['']
    });
  }

  ngOnInit() {
    this.form.get('companyId').setValue(this.company.companyId);
  }

  async createContact() {
    let loading = await this.loadingController.create({
      message:'Sending data',
      mode:'ios',
      spinner:'dots'
    });
    loading.present();
    this.api.post('contact/InsertOrUpdate',this.form.value).then((res:any) => {
      let contact = this.form.value;
      contact.contactId  = res.id;
      contact.contactKey = res.key;
      this.storageService.setItem('currentContact',contact);
      this.utils.showToast('Data saved correctly');
      this.dismiss();
    }).catch(()=> {
      this.utils.showAlert('Error processing your data, please try again');
    }).finally(()=> {
      loading.dismiss();
    })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
      session: this.currentSession,
    });
  }

}
