import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const maskConfig: Partial<IConfig> = {
  validation: true,
};
//Plugins
import { File } from '@ionic-native/file/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Printer } from '@ionic-native/printer/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    NgxMaskModule.forRoot(maskConfig),
    BrowserModule,
    IonicModule.forRoot(),
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: LOCALE_ID, useValue: "en-US" },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    File,
    FileOpener,
    Printer,
    ScreenOrientation,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
