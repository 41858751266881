import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from 'src/app/models/Company';

const SELISSEO_SESSION = "selisseo_session";
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private subject = new BehaviorSubject<Session>(null);
  readonly session$: Observable<Session> = this.subject.asObservable();

  isLogginIn$: Observable<boolean>;
  isLogginOut$: Observable<boolean>;


  constructor() {
    this.isLogginIn$ = this.session$.pipe(map(session => !!session));
    this.isLogginOut$ = this.isLogginIn$.pipe(map(loggedIn => !loggedIn));
    if (localStorage.getItem(SELISSEO_SESSION)) {
      this.subject.next(JSON.parse(localStorage.getItem(SELISSEO_SESSION)));
    }
  }

  setSession(session: Session) {
    localStorage.setItem(SELISSEO_SESSION, JSON.stringify(session));
    this.subject.next(session);
  }

  deleteSession() {
    localStorage.removeItem(SELISSEO_SESSION);
  }



}

export interface Session {
  company: Company,
  description?: string
}

