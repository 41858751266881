import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import * as _ from 'lodash';
import { Source } from 'src/app/models/Source';
import { Scenario } from 'src/app/models/Scenario';
import { ScenariosService } from 'src/app/services/scenarios/scenarios.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  summary = [
    {
      item: 'annualCostUsd',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'quaterlyCostUsd',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'deltaCostUsd',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'sumProgramCostUsdST',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'sumProgramCostUsdMT',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'deltaCostUsdST',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'potSeVolMT',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'potSeVolST',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'SeMet',
      data:[
        {
          icon: 'S1',
          total: 0
        },
        {
          icon: 'S2',
          total:0
        },
        {
          icon: 'S3',
          total:0
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0
        }
      ],
      minValue: 0
    }
  ];

  data1 = [
    {
      icon: 'SS',
      total: 0
    },
    {
      icon: 'SY',
      total: 3.572
    },
    {
      icon: 'SO',
      total: 4.784
    },
    {
      icon: '1/2 S1 & 1/2 S2',
      total: 1.984
    },
    {
      icon: '1/2 S1 & 1/2 S3',
      total: 2.590
    },
    {
      icon: 'EqS 1/2 S1 & 1/2 S2',
      total: 1.932
    }
  ];

  data2 = [
    {
      icon: 'SS',
      total: 99
    },
    {
      icon: 'SY',
      total: 893
    },
    {
      icon: 'SO',
      total: 1196
    },
    {
      icon: '1/2 S1 & 1/2 S2',
      total: 496
    },
    {
      icon: '1/2 S1 & 1/2 S3',
      total: 648
    },
    {
      icon: 'EqS 1/2 S1 & 1/2 S2',
      total: 483
    }
  ];

  data3 = [
    {
      icon: 'SS',
      total: 0.064
    },
    {
      icon: 'SY',
      total: 0.572
    },
    {
      icon: 'SO',
      total: 0.765
    },
    {
      icon: '1/2 S1 & 1/2 S2',
      total: 0.318
    },
    {
      icon: '1/2 S1 & 1/2 S3',
      total: 0.414
    },
    {
      icon: 'EqS 1/2 S1 & 1/2 S2',
      total: 0.309
    }
  ];


  data4 = [
    {
      icon: 'SS',
      total: 0.064
    },
    {
      icon: 'SY',
      total: 0.572
    },
    {
      icon: 'SO',
      total: 0.765
    },
    {
      icon: '1/2 S1 & 1/2 S2',
      total: 0.318
    },
    {
      icon: '1/2 S1 & 1/2 S3',
      total: 0.414
    },
    {
      icon: 'EqS 1/2 S1 & 1/2 S2',
      total: 0.309
    }
  ];


  s2AndS1: string;
  s3AndS1: string;
  eqS2AndS1: string;


  blend1S3: string;
  blend1S1: string;
  blend2S2: string;
  blend2S1: string;
  blend3S2: string;
  blend3S1: string;

  ssAmountRequiredDietFormatted: string;
  syAmountRequiredDietFormatted: string;
  soAmountRequiredDietFormatted: string;



  barChart1: any;
  barChart2: any;
  barChart3: any;
  barChart4: any;

  ctx1: any;
  ctx2: any;
  ctx3: any;
  ctx4: any;

  chart1: Chart;
  chart2: Chart;
  chart3: Chart;
  chart4: Chart;

  backgroundColors: string[] = [];

  source1: Source = {
    id: 0,
    name: "",
    pctSeContent: 0.0,
    pctAvailableSeMet: 0.00,
    costLb: 0.05,
    seleniumInclusion: {
      puritySe: 0.0,
      pctAmountSe: 0.0,
      pctAvailableSeMet: 0.0,
      additionRateSeKgMT: 0.0,
      additionRateSegMT: 0.0,
      additionRateSeKgST: 0.0,
      additionRateSeLbST: 0.0
    },
    costSource: {
      costKg: 0.0,
      costKgMt: 0.0,
      costLb: 0.0,
      costLbSt: 0.0
    },
    annualFeedAndSourceTonnage: {
      feedVolMt: 0.0,
      potSeVolKg: 0.0,
      potSeVolMt: 0.0,
      potSeVolLbs: 0.0,
      potSeVolSt: 0.0,
      costUsdLb: 0.0,
      costUsdMt: 0.0,
      costUsdShorTon: 0.0,
      costUsdMetricTon: 0.0,
      costKgMt: 0.0,
      costLbSt: 0.0,
    },
    quaterlyFeedAndSourceTonnage: {
      feedVolSt: 0.0,
      feedVolMt: 0.0,
      potSeVolKg: 0.0,
      potSeVolMt: 0.0,
      potSeVolLbs: 0.0,
      potSeVolSt: 0.0,
      costUsdLb: 0.0,
      costUsdMt: 0.0,
      costKgMt: 0.0,
      costLbSt: 0.0,
    }
  }

  currentScenario: Scenario = {
    name: '',
    companyId: 0,
    contactId: 0,
    date: new Date,
    description: '',
    optionOnePercent: 0,
    dataSource: {
      source1: this.source1,
      source2: this.source1,
      source3: this.source1,
      halfSource1: this.source1,
      halfSource2: this.source1,
      halfSource3: this.source1,
      thirdSource1: this.source1,
      twoThirdSource2: this.source1,
      twoThirdSource3:this.source1,
      amountRequiredDiet: 0.000,
      feedVolSt: 0,
      measure: {
        id: 'i',
        name: 'imperial'
      },
    },

  }

  constructor(
    private modalCtrl: ModalController,
    private _scenarios: ScenariosService
  ) { }

  ngOnInit() {

    this._scenarios.scenario$.subscribe(scenario => {
      this.currentScenario = scenario;
      this.SetHeaderNames();
      this.SetTableValues(this.currentScenario);
      this.setGraphicsValues();
      this.createAreaChart();
    });

  }

  private SetHeaderNames() {
    const equivalentPercent = Math.round((100 * this.currentScenario.dataSource.equivalentHalfSource2.amountRequiredDiet / this.currentScenario.dataSource.amountRequiredDiet));
    const roundedOptionOnePercentMinus100 = Math.round(Math.round((100 - this.currentScenario.optionOnePercent)));
    const roundedEquivalentPercentMinus100 = Math.round(100 - equivalentPercent);


    // 100% of Se is from NaSelenite _%
    // 100% of Se is from SeYeast_%
    // 100% of Se is from Selisseo_%
    // 50% of Se is from SeYeast_%
    // 50% of Se is from NaSelenite_%
    // 50% of Se is from Selisseo_%
    // 50% of Se is from NaSelenite_%

   this.ssAmountRequiredDietFormatted = Number(this.currentScenario.dataSource?.source1.amountRequiredDiet * (this.currentScenario.dataSource?.source1.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3);
   this.syAmountRequiredDietFormatted =  Number(this.currentScenario.dataSource?.source3.amountRequiredDiet * (this.currentScenario.dataSource?.source3.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3);
   this.soAmountRequiredDietFormatted =  Number(this.currentScenario.dataSource?.source2.amountRequiredDiet * (this.currentScenario.dataSource?.source2.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3);



    this.blend1S1 = roundedOptionOnePercentMinus100 +'% of Se is from '+this.currentScenario.dataSource?.source1.name;
    this.blend1S3 = this.currentScenario.optionOnePercent +'% of Se is from '+this.currentScenario.dataSource?.source3.name;
    this.blend2S1 = roundedOptionOnePercentMinus100 +'% of Se is from '+this.currentScenario.dataSource?.source1.name;
    this.blend2S2 = this.currentScenario.optionOnePercent +'% of Se is from '+this.currentScenario.dataSource?.source2.name;
    this.blend3S1 = roundedEquivalentPercentMinus100+'% of Se is from '+this.currentScenario.dataSource?.source1.name;
    this.blend3S2 =  equivalentPercent +'% of Se is from '+this.currentScenario.dataSource?.source2.name;






    this.s3AndS1 = this.currentScenario.optionOnePercent +'% SY & '+roundedOptionOnePercentMinus100+'% SS';
    this.s2AndS1 = this.currentScenario.optionOnePercent +'% SO & '+roundedOptionOnePercentMinus100  +'% SS';
    this.eqS2AndS1 = 'Eq SeMet '+equivalentPercent  +'% SO & ' +roundedEquivalentPercentMinus100+'% SS';
    this.data1[3].icon = this.s3AndS1;
    this.data1[4].icon = this.s2AndS1;
    this.data1[5].icon = this.eqS2AndS1;

    this.data2[3].icon = this.s3AndS1;
    this.data2[4].icon = this.s2AndS1;
    this.data2[5].icon = this.eqS2AndS1;

    this.data3[3].icon = this.s3AndS1;
    this.data3[4].icon = this.s2AndS1;
    this.data3[5].icon = this.eqS2AndS1;


    this.data4[3].icon = this.s3AndS1;
    this.data4[4].icon = this.s2AndS1;
    this.data4[5].icon = this.eqS2AndS1;
  }




  dismiss() {
    this.modalCtrl.dismiss({
    })
    ;
  }

   SetTableValues(currentScenario: Scenario) {
    this.summary[0].data[0].total = currentScenario.dataSource?.source1.annualFeedAndSourceTonnage?.costUsdLb;
    this.summary[0].data[1].total = currentScenario.dataSource?.source3.annualFeedAndSourceTonnage?.costUsdLb;
    this.summary[0].data[2].total = currentScenario.dataSource?.source2.annualFeedAndSourceTonnage?.costUsdLb;
    this.summary[0].data[3].total = (currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource3.annualFeedAndSourceTonnage?.costUsdLb);
    this.summary[0].data[4].total = (currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource2.annualFeedAndSourceTonnage?.costUsdLb);
    this.summary[0].data[5].total = (currentScenario.dataSource?.equivalentHalfSource1.annualFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.equivalentHalfSource2.annualFeedAndSourceTonnage?.costUsdLb);

    this.summary[0].minValue = Math.min.apply(null, this.summary[0].data.map(item => item.total));



    // Quarterly Cost, USD
    this.summary[1].data[0].total = currentScenario.dataSource?.source1.quaterlyFeedAndSourceTonnage?.costUsdLb;
    this.summary[1].data[1].total = currentScenario.dataSource?.source3.quaterlyFeedAndSourceTonnage?.costUsdLb;
    this.summary[1].data[2].total = currentScenario.dataSource?.source2.quaterlyFeedAndSourceTonnage?.costUsdLb;
    this.summary[1].data[3].total = (currentScenario.dataSource?.halfSource1.quaterlyFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource3.quaterlyFeedAndSourceTonnage?.costUsdLb);
    this.summary[1].data[4].total = (currentScenario.dataSource?.halfSource1.quaterlyFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource2.quaterlyFeedAndSourceTonnage?.costUsdLb);
    this.summary[1].data[5].total = (currentScenario.dataSource?.equivalentHalfSource1.quaterlyFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.equivalentHalfSource2.quaterlyFeedAndSourceTonnage?.costUsdLb);

    this.summary[1].minValue = Math.min.apply(null, this.summary[1].data.map(item => item.total));




    //Delta vs. 100% Selisseo, USD
    this.summary[2].data[0].total = this.summary[0].data[2].total - this.summary[0].data[0].total;
    this.summary[2].data[1].total = this.summary[0].data[2].total - this.summary[0].data[1].total;
    // this.summary[2].data[2].total =  this is 100% selisseo .. no point in doing calculation;
    this.summary[2].data[3].total = this.summary[0].data[2].total - this.summary[0].data[3].total;
    this.summary[2].data[4].total = this.summary[0].data[2].total - this.summary[0].data[4].total;
    this.summary[2].data[5].total = this.summary[0].data[2].total - this.summary[0].data[5].total;

    this.summary[2].minValue = Math.max.apply(null, (this.summary[2].data.filter((item) => { return item.total > 0; }).map(item => item.total)));



    // Cost Per treated Short ton, $/ ST
    this.summary[3].data[0].total = currentScenario.dataSource?.source1.costSource?.costLbSt;
    this.summary[3].data[1].total = currentScenario.dataSource?.source3.costSource?.costLbSt;
    this.summary[3].data[2].total = currentScenario.dataSource?.source2.costSource?.costLbSt;
    this.summary[3].data[3].total = (currentScenario.dataSource?.halfSource1.costSource?.costLbSt + currentScenario.dataSource?.halfSource3.costSource?.costLbSt);
    this.summary[3].data[4].total = (currentScenario.dataSource?.halfSource1.costSource?.costLbSt + currentScenario.dataSource?.halfSource2.costSource?.costLbSt);
    this.summary[3].data[5].total =  (currentScenario.dataSource?.equivalentHalfSource1.costSource?.costLbSt + currentScenario.dataSource?.equivalentHalfSource2.costSource?.costLbSt);

    this.summary[3].minValue = Math.min.apply(null, this.summary[3].data.map(item => item.total));

    // Cost Per treated Metric ton, $/ MT

    this.summary[4].data[0].total =  currentScenario.dataSource?.source1.costSource?.costKgMt;
    this.summary[4].data[1].total =  currentScenario.dataSource?.source3.costSource?.costKgMt;
    this.summary[4].data[2].total =  currentScenario.dataSource?.source2.costSource?.costKgMt;
    this.summary[4].data[3].total =  (currentScenario.dataSource?.halfSource1.costSource?.costKgMt + currentScenario.dataSource?.halfSource3.costSource?.costKgMt);
    this.summary[4].data[4].total =  (currentScenario.dataSource?.halfSource1.costSource?.costKgMt + currentScenario.dataSource?.halfSource2.costSource?.costKgMt);
    this.summary[4].data[5].total =   (currentScenario.dataSource?.equivalentHalfSource1.costSource?.costKgMt + currentScenario.dataSource?.equivalentHalfSource2.costSource?.costKgMt);

    this.summary[4].minValue = Math.min.apply(null, this.summary[4].data.map(item => item.total));

    //Delta vs. 100% Selisseo, USD ST
    this.summary[5].data[0].total = this.summary[3].data[2].total - this.summary[3].data[0].total;
    this.summary[5].data[1].total = this.summary[3].data[2].total - this.summary[3].data[1].total;
    // this.summary[3].data[2].total =  this is 100% selisseo .. no point in doing calculation;
    this.summary[5].data[3].total = this.summary[3].data[2].total - this.summary[3].data[3].total;
    this.summary[5].data[4].total = this.summary[3].data[2].total - this.summary[3].data[4].total;
    this.summary[5].data[5].total = this.summary[3].data[2].total - this.summary[3].data[5].total;

    this.summary[5].minValue = Math.max.apply(null, (this.summary[5].data.filter((item) => { return item.total > 0; }).map(item => item.total)));


    //Potential Org.Selenium volumes, metric ton
    this.summary[6].data[0].total = currentScenario.dataSource?.source1.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[1].total = currentScenario.dataSource?.source3.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[2].total = currentScenario.dataSource?.source2.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[3].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolMt + currentScenario.dataSource?.halfSource3.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[4].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolMt + currentScenario.dataSource?.halfSource2.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[5].total = currentScenario.dataSource?.equivalentHalfSource1.annualFeedAndSourceTonnage?.potSeVolMt + currentScenario.dataSource?.equivalentHalfSource2.annualFeedAndSourceTonnage?.potSeVolMt;

    this.summary[6].minValue = Math.min.apply(null, this.summary[6].data.map(item => item.total));

    //Potential Org.Selenium volumes, short ton
    this.summary[7].data[0].total = currentScenario.dataSource?.source1.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[1].total = currentScenario.dataSource?.source3.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[2].total = currentScenario.dataSource?.source2.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[3].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolSt + currentScenario.dataSource?.halfSource3.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[4].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolSt + currentScenario.dataSource?.halfSource2.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[5].total = currentScenario.dataSource?.equivalentHalfSource1.annualFeedAndSourceTonnage?.potSeVolSt + currentScenario.dataSource?.equivalentHalfSource2.annualFeedAndSourceTonnage?.potSeVolSt;


    this.summary[7].minValue = Math.min.apply(null, this.summary[7].data.map(item => item.total));

    // Cost per gram of SeMet
    this.summary[8].data[0].total = (currentScenario.dataSource?.source1.costSource?.costUnitSeMetLb);
    this.summary[8].data[1].total = (currentScenario.dataSource?.source3.costSource?.costUnitSeMetLb);
    this.summary[8].data[2].total = (currentScenario.dataSource?.source2.costSource?.costUnitSeMetLb);
    this.summary[8].data[3].total = (currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb != 0 ? currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb : 0) + currentScenario.dataSource?.halfSource3.costSource?.costUnitSeMetLb;

    this.summary[8].data[4].total = (currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb != 0 ? currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb : 0) + currentScenario.dataSource?.halfSource2.costSource?.costUnitSeMetLb;
    this.summary[8].data[5].total = (currentScenario.dataSource?.equivalentHalfSource1.costSource?.costUnitSeMetLb != 0 ? currentScenario.dataSource?.equivalentHalfSource1.costSource?.costUnitSeMetLb : 0) + currentScenario.dataSource?.equivalentHalfSource2.costSource?.costUnitSeMetLb;

    this.summary[8].minValue = Math.min.apply(null, (this.summary[8].data.filter((item) => { return item.total > 0; }).map(item => item.total)));

  }

  setGraphicsValues(){


    //  Quarterly Cost, USD
    this.data1[0].total = this.summary[1].data[0].total;
    this.data1[1].total = this.summary[1].data[1].total;
    this.data1[2].total = this.summary[1].data[2].total;
    this.data1[3].total = this.summary[1].data[3].total;
    this.data1[4].total = this.summary[1].data[4].total;
    this.data1[5].total = this.summary[1].data[5].total;


    // Cost Per treated Short ton, $/ ST

    this.data2[0].total = this.summary[3].data[0].total;
    this.data2[1].total = this.summary[3].data[1].total;
    this.data2[2].total = this.summary[3].data[2].total;
    this.data2[3].total = this.summary[3].data[3].total;
    this.data2[4].total = this.summary[3].data[4].total;
    this.data2[5].total = this.summary[3].data[5].total;



     // Cost Per treated Metric ton, $/ MT
     this.data3[0].total = this.summary[4].data[0].total;
     this.data3[1].total = this.summary[4].data[1].total;
     this.data3[2].total = this.summary[4].data[2].total;
     this.data3[3].total = this.summary[4].data[3].total;
     this.data3[4].total = this.summary[4].data[4].total;
     this.data3[5].total = this.summary[4].data[5].total;

     //Cost per gram of SeMet
       this.data4[0].total = this.summary[8].data[0].total;
       this.data4[1].total = this.summary[8].data[1].total;
       this.data4[2].total = this.summary[8].data[2].total;
       this.data4[3].total = this.summary[8].data[3].total;
       this.data4[4].total = this.summary[8].data[4].total;
       this.data4[5].total = this.summary[8].data[5].total;

     }
  createAreaChart() {

    this.barChart1 = document.getElementById('barChart1');
    this.barChart2 = document.getElementById('barChart2');
    this.barChart3 = document.getElementById('barChart3');
    this.barChart4 = document.getElementById('barChart4');

    this.ctx1 = this.barChart1.getContext('2d');
    this.ctx2 = this.barChart2.getContext('2d');
    this.ctx3 = this.barChart3.getContext('2d');
    this.ctx4 = this.barChart4.getContext('2d');

    // this.backgroundColors.push('#A60A2D');//1
    // this.backgroundColors.push('#00A2C3');//2
    // this.backgroundColors.push('#434F55');//3

    //Gradients
    // var heavyGrayGradient = this.ctx1.createLinearGradient(0, 0 , 0, 250);
    // heavyGrayGradient.addColorStop(1, 'rgba(163, 168, 174, 1)');
    // heavyGrayGradient.addColorStop(0, 'rgba(72, 83, 93, 1)');

    // var grayGradient = this.ctx1.createLinearGradient(0, 0, 0, 250);
    // grayGradient.addColorStop(0, 'rgba(229, 229, 229, 1)');
    // grayGradient.addColorStop(1, 'rgba(150, 151, 151, 1)');

    // var adisseoGradient = this.ctx1.createLinearGradient(0, 0, 0, 250);
    // adisseoGradient.addColorStop(0, 'rgba(166, 10, 45)');
    // adisseoGradient.addColorStop(1, 'rgba(139, 20, 47)');



    let i = 0;
    this.data1.forEach(div => {
      if(i==0){
        this.backgroundColors.push('#A3A8AE');
    // this.backgroundColors.push(heavyGrayGradient);

      } if(i==1) {
        this.backgroundColors.push('#C4C4C4');
    // this.backgroundColors.push(grayGradient);


      } if(i==2) {
        this.backgroundColors.push('#A60A2D');
        // this.backgroundColors.push(adisseoGradient);

        i = -1;
      }
      if(i==3) {
        this.backgroundColors.push('#A3A8AE');
        // this.backgroundColors.push(adisseoGradient);
        i = -1;
      }
      i++;
    });

    this.chart1 = new Chart(this.ctx1, {
      type: 'horizontalBar',
      data: {
        labels: this.data1.map(r => r.icon),
        datasets: [{
          data: this.data1.map(r => r.total),
          label: 'Quarterly Cost',
          backgroundColor: this.backgroundColors,
          borderColor: this.backgroundColors,
          fillColor: this.backgroundColors,
          borderWidth: 1,
          barPercentage: 0.5
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index];
              if (parseInt(value) >= 1000) {
                return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return '$' + value.toFixed(2).toString();
              }
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              min: 0,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              mirror: true,
               fontSize: 15,
              labelOffset: -21,
              fontStyle: "bold",
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                } else {
                  return '' + value;
                }
              }
            }
          }]
        }
      },
    });
    this.chart1.height = 287;

    this.chart2 = new Chart(this.ctx2, {
      type: 'horizontalBar',
      data: {
        labels: this.data2.map(r => r.icon),
        datasets: [{
          data: this.data2.map(r => r.total),
          label: 'Cost per treated ST',
          backgroundColor: this.backgroundColors,
          borderColor: this.backgroundColors,
          borderWidth: 1,
          barPercentage: 0.5
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index];
              if (parseInt(value) >= 1000) {
                return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return '$' + value.toFixed(2).toString();
              }
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              min: 0,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              mirror: true,
               fontSize: 15,
              labelOffset: -21,
              fontStyle: "bold",
              precision: 2,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }]
        }
      }
    });
    this.chart2.height = 287;

    this.chart3 = new Chart(this.ctx3, {
      type: 'horizontalBar',
      data: {
        labels: this.data3.map(r => r.icon),
        datasets: [{
          data: this.data3.map(r => r.total),
          label: 'Cost per treated MT',
          backgroundColor: this.backgroundColors,
          borderColor: this.backgroundColors,
          borderWidth: 1,
          barPercentage: 0.5
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index];
              if (parseInt(value) >= 1000) {
                return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return '$' + value.toFixed(2).toString();
              }
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              min: 0,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              mirror: true,
              fontSize: 15,
              labelOffset: -21,
              fontStyle: "bold",
              // beginAtZero: true,
              precision: 2,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }]
        }
      }
    });
    this.chart3.height = 287;

    this.chart4 = new Chart(this.ctx4, {
      type: 'horizontalBar',
      data: {
        labels: this.data4.map(r => r.icon),
        datasets: [{
          data: this.data4.map(r => r.total),
          label: 'Cost per gram of SeMet',
          backgroundColor: this.backgroundColors,
          borderColor: this.backgroundColors,
          borderWidth: 1,
          barPercentage: 0.5
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index];
              if (parseInt(value) >= 1000) {
                return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return '$' + value.toFixed(2).toString();
              }
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              min: 0,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              mirror: true,
              fontSize: 15,
              labelOffset: -21,
              fontStyle: "bold",
              // beginAtZero: true,
              precision: 2,
              userCallback : function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return '' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '' + value;
                }
              }
            }
          }]
        }
      }
    });
    this.chart4.height = 287;
  }

}
