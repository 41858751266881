import { MiscService } from './../../services/misc/misc.service';
import { Component, OnInit } from '@angular/core';

import { LoadingService } from './../../services/loading/loading.service';
@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  // isLoading = false;

  constructor(
    private _loader: LoadingService,
    public _misc: MiscService
  ) { }

  ngOnInit() {}

  on() {
    this._loader.loading = true;
  }

  off() {
    this._loader.loading = false;
  }

  isLoading() {
    return this._loader.loading;
  }

}
