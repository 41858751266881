import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { Company } from 'src/app/models/Company';
import { Contact } from 'src/app/models/Contact';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss'],
})
export class EditContactComponent implements OnInit {

  currentSession;
  company:Company;
  contactData: Contact;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private api: ApiService,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.form = this.fb.group({
      companyId       : ['', Validators.required],
      contactId       : ['', Validators.required],
      titleName       : ['', Validators.required],
      firstName       : ['', Validators.required],
      lastName        : ['', Validators.required],
      email           : ['', [Validators.required, Validators.email]],
      phone           : ['', Validators.required],
      extension       : [''],
      cellPhone       : ['']
    });
  }

  ngOnInit() {
    setTimeout(()=> {
      this.form.get('companyId').setValue(this.contactData.companyId);
      this.form.get('contactId').setValue(this.contactData.contactId);
      this.form.get('titleName').setValue(this.contactData.titleViewModel!=null?this.contactData.titleViewModel.name:'');
      this.form.get('firstName').setValue(this.contactData.firstName);
      this.form.get('lastName').setValue(this.contactData.lastName);
      this.form.get('email').setValue(this.contactData.email);
      this.form.get('phone').setValue(this.contactData.phone);
      this.form.get('extension').setValue(this.contactData.extension);
      this.form.get('cellPhone').setValue(this.contactData.cellPhone);
    },1500)
  }

  async createContact() {
    let loading = await this.loadingController.create({
      message:'Sending data',
      mode:'ios',
      spinner:'dots'
    });
    loading.present();
    this.api.post('contact/InsertOrUpdate',this.form.value).then((res) => {
      this.utils.showToast('Data saved correctly');
      this.storageService.setItem('currentContact', this.form.value);
      this.dismiss();
    }).catch(()=> {
      this.utils.showAlert('Error processing your data, please try again');
    }).finally(()=> {
      loading.dismiss();
    })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
      session: this.currentSession,
    });
  }

}
