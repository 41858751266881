import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'admin/usuarios',
    loadChildren: () => import('./pages/admin/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'sessions',
    loadChildren: () => import('./pages/sessions/sessions.module').then( m => m.SessionsPageModule)
  },
  {
    path: 'new-session',
    loadChildren: () => import('./pages/sessions/new-session/new-session.module').then( m => m.NewSessionPageModule)
  },
  {
    path: 'scenarios',
    loadChildren: () => import('./pages/scenarios/scenarios.module').then( m => m.ScenariosPageModule)
  },
  {
    path: 'scenario',
    loadChildren: () => import('./pages/scenarios/scenario/scenario.module').then( m => m.ScenarioPageModule)
  },
  // {
  //   path: 'scenario/:id',
  //   loadChildren: () => import('./pages/scenarios/scenario/scenario.module').then( m => m.ScenarioPageModule)
  // },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'new-client',
    loadChildren: () => import('./pages/clients/new-client/new-client.module').then( m => m.NewClientPageModule)
  },
  {path: '**', redirectTo: '/login'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
