import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from './../directives/directives.module';

import { NavbarComponent } from './layout/navbar/navbar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LoaderComponent } from './loader/loader.component';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask'

import { PrintScenarioComponent } from './modals/print-scenario/print-scenario.component';
import { EditScenarioComponent } from './modals/edit-scenario/edit-scenario.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';
import { NewUserComponent } from './modals/user/new-user/new-user.component';
import { ViewUserComponent } from './modals/user/view-user/view-user.component';
import { NewSessionComponent } from './modals/new-session/new-session.component';
import { NewScenarioComponent } from './modals/new-scenario/new-scenario.component';
import { SetComparisonComponent } from './modals/set-comparison/set-comparison.component';
import { NewContactComponent } from './modals/new-contact/new-contact.component';
import { NewCompanyComponent } from './modals/new-company/new-company.component';
import { SummaryComponent } from './modals/summary/summary.component';
import { EditContactComponent } from './modals/edit-contact/edit-contact.component';
import { DeleteContactComponent } from './modals/delete-contact/delete-contact.component';
import { ForgotPasswordComponent } from './modals/forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    LoaderComponent,
    UserMenuComponent,
    NewUserComponent,
    ViewUserComponent,
    SetComparisonComponent,
    NewScenarioComponent,
    NewSessionComponent,
    SummaryComponent,
    NewCompanyComponent,
    NewContactComponent,
    EditContactComponent,
    EditScenarioComponent,
    PrintScenarioComponent,
    DeleteContactComponent,
    ForgotPasswordComponent
  ],
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    IonicModule,
    RouterModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatTooltipModule,
    MatStepperModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    LoaderComponent,
    UserMenuComponent,
    NewUserComponent,
    ViewUserComponent,
    SetComparisonComponent,
    NewScenarioComponent,
    NewSessionComponent,
    SummaryComponent,
    NewCompanyComponent,
    NewContactComponent,
    EditContactComponent,
    EditScenarioComponent,
    PrintScenarioComponent,
    DeleteContactComponent,
    ForgotPasswordComponent
  ]
})
export class ComponentsModule { }
