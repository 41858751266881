import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading;
  loaderContent = ''

  constructor(
    public loadingCtrl: LoadingController
  ) {
    
    this.loaderContent = `<div class="loader-modal">
      <div class="loader-container">
        <div class="logo" data-aos="fade-left" data-aos-anchor=".loader-modal" data-aos-duration="1000">
          <img src="./assets/imgs/cargando.gif" alt="" class="crol-logo">
        </div>
        <span class="animate__animated animate__bounce">cargando</span>
      </div>
    </div>`;  
  }

  async on() {
    this.loading = true;
  }

  async off() {
    this.loading = false;
  }
  
}
