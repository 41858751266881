import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Measure } from 'src/app/models/Measure';
import { StorageService } from '../storage/storage.service';

const SELISSEO_MEASURE = "selisseo_measure";

@Injectable({
  providedIn: 'root'
})
export class MeasuresService {

  private measureSubject = new BehaviorSubject<Measure>(null);
  readonly measure$: Observable<Measure> = this.measureSubject.asObservable();

  measures: Measure[] = [
    {
      id: 'i',
      name: 'imperial'
    },
    {
      id: 'm',
      name: 'metric'
    }
  ];
  selectedMeasure = this.measures[0];

  constructor(
    private storageService: StorageService
  ) {
    if (localStorage.getItem(SELISSEO_MEASURE)) {
      this.measureSubject.next(JSON.parse(localStorage.getItem(SELISSEO_MEASURE)));
    }
  }

  get currentMeasure() {
    return this.measureSubject.value;
  }

  selectMeasure(measureId) {
    this.selectedMeasure = this.measures.find(m => m.id === measureId);
    this.storageService.setItem('selisseo_measure',this.selectedMeasure);
    localStorage.setItem(SELISSEO_MEASURE, JSON.stringify(this.selectedMeasure));
    this.measureSubject.next(this.selectedMeasure);
  }

  toggleMeasure() {
    this.selectedMeasure = this.measures.find(m => m.id === this.measureSubject.value.id );
    console.log(this.selectedMeasure)

    // this.selectedMeasure = this.measures.find(m => m.id === measureId);
    this.storageService.setItem('selisseo_measure',this.selectedMeasure);
    localStorage.setItem(SELISSEO_MEASURE, JSON.stringify(this.selectedMeasure));
    this.measureSubject.next(this.selectedMeasure);
  }


  getSelectedMeasure() {
    return JSON.parse(localStorage.getItem('selisseo_measure')??'{}');
  }
}
