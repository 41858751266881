import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { Company } from 'src/app/models/Company';
import { Contact } from 'src/app/models/Contact';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-delete-contact',
  templateUrl: './delete-contact.component.html',
  styleUrls: ['./delete-contact.component.scss'],
})
export class DeleteContactComponent implements OnInit {

  currentSession;
  company:Company;
  contact : Contact;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private api: ApiService,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.form = this.fb.group({
      companyId       : ['', Validators.required],
      contactId       : ['', Validators.required],
    });
  }

  ngOnInit() {
    setTimeout(()=> {
      this.form.get('companyId').setValue(this.contact.companyId);
      this.form.get('contactId').setValue(this.contact.contactId);
    },1500)
  }

  async deleteContact() {
    let loading = await this.loadingController.create({
      message:'Deleting contact...',
      mode:'ios',
      spinner:'dots'
    });
    loading.present();
    this.api.post('contact/delete',this.form.value).then((res) => {
      this.utils.showToast('Contact deleted successfully.');
      this.dismiss();
    }).catch(()=> {
      this.utils.showAlert('Error processing your data, please try again.');
    }).finally(()=> {
      loading.dismiss();
    })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
      session: this.currentSession,
    });
  }

}
