import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { DisableRightClickDirective } from './disableRightClick/disable-right-click.directive';

@NgModule({
  declarations: [
    DisableRightClickDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule
  ],
  exports: [
    DisableRightClickDirective
  ]
})
export class DirectivesModule { }
