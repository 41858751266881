import { Component, ElementRef, OnInit, Input, HostListener, NgZone, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

import { AuthService } from './../../../services/auth/auth.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  public isOpen: boolean = false;

  innerWidth = 0;

  constructor(
    private zone: NgZone,
    private router: Router,
    private alertCtrl: AlertController,
    public elementRef: ElementRef,
    public _auth: AuthService
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  closeSidebar() {
    this.isOpen = false;
  }

  goToProfile(){
    this.router.navigate(['/perfil']);

  }

  goToHistory(){
    this.router.navigate(['/historial']);
  }

  async doLogout() {
    const alert = await this.alertCtrl.create({
      header: "Cerrar sesión",
      message: '¿Está seguro de salir de la sesión actual?',
      buttons: [
        {
          text: 'Cancelar',
          cssClass: 'btn btn-default mb-0',
          role: 'cancel'
        },
        {
          text: 'Aceptar',
          cssClass: 'btn btn-primary',
          handler: async () => {
            await this._auth.logout();
            this.zone.run(async () => {
              this.router.navigate(['/']);
            })
          }
        }
      ]
    })
    alert.present();
  }

}
