import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { Company } from 'src/app/models/Company';
import { Contact } from 'src/app/models/Contact';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private _api: ApiService
  ) { }

  getClients(params:any=null): Promise<Company[]> {

    return this._api.get('company/get',params).then((res:any) => {
      let companies:Array<Company>;
      companies = res.items;
      return companies;
    });
  }
  
  getContacts(companyId?): Promise<Contact[]> {
    if (companyId) {
      return this._api.get('contacts/company/get',{companyId:companyId}).then((contacts: Contact[]) => {
        return contacts;
      });
    } else {
      return this._api.get('contacts/get').then((contacts: Contact[]) => {
        return contacts;
      });
    }
  }

}