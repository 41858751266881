import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as _ from 'lodash';
import { StorageService } from '../storage/storage.service';
import { ApiService } from '../api/api.service';
import { User } from 'src/app/models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userSubject = new BehaviorSubject<User>(null);
  user$: Observable<User> = this.userSubject.asObservable();
  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;

  constructor(
    private storage: Storage,
    private alertCtrl: AlertController,
    private _api: ApiService,
    private storageService: StorageService
  ) {
    this.loadUser();
  }
  
  loadUser() {
    return  JSON.parse(localStorage.getItem('user')??'{}');
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(credentials): Promise<any> {
    return this._api.security_post('Login', credentials)
    .then((res: any) => {
      this.storageService.setItem('token',res);
      return res;
    }).catch(e => {
      this.logout();
      return e;
    })
  }

  changePassword(user: User) {
    return this._api.post('usuarios/changepassword', user).then(res => {
      return res;
    })
  }

  resetPassword(user: User) {
    user.password = user.rpe;
    return this._api.post('usuarios/changepassword', user).then(res => {
      return res;
    })
  }

  async logout() {
    return new Promise(async (resolve) => {
      await this.storage.remove('access_token');
      localStorage.removeItem('user');
      this.userSubject.next(null)
      resolve({ message: "Sesión finalizada por usuario..." })
    })
  }

  async passwordAlert() {
    let alert = await this.alertCtrl.create({
      header: 'Cambiar contraseña',
      inputs: [
        {
          name: 'password1',
          placeholder: 'Nueva contraseña',
          type: 'password'
        },
        {
          name: 'password2',
          placeholder: 'Confirmar contraseña',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          cssClass: 'btn btn-default',
          role: 'cancel'
        },
        {
          text: 'Guardar',
          cssClass: 'btn btn-primary',
          handler: data => {
            let user: any = _.cloneDeep(this.userValue)
            user.password = data.password1;
            this.changePassword(user).then(async () => {
              let alert = await this.alertCtrl.create({
                header: 'Éxito',
                message: 'Contrase&ntilde;a restablecida',
                buttons: [
                {
                  text: 'Aceptar',
                  cssClass: 'btn btn-primary',
                  role: 'cancel'
                }
              ]
              })
              alert.present();
            })
          }
        }
      ]
    })
    alert.present();
  }

}
