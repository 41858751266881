import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'],
})
export class NewCompanyComponent implements OnInit {

  currentSession;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private api: ApiService,
    private loadingController: LoadingController,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.form = this.fb.group({
      name          : ['', [Validators.required]],
      description   : [''],
      lineOne       : ['', Validators.required],
      lineTwo       : [''],
      state         : ['', Validators.required],
      city          : ['', Validators.required],
      postalCode    : ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });
  }

  ngOnInit() {}

  async createCompany() {
    let loading = await this.loadingController.create({
      message:'Sending data',
      mode:'ios',
      spinner:'dots'
    });
    loading.present();
    this.api.post('company/InsertOrUpdate',this.form.value).then((res:any) => {
      let company = this.form.value;
      company.companyId  = res.id;
      company.companyKey = res.key;
      this.storageService.setItem('currentCompany',company);
      this.utils.showToast('Data saved correctly');
      this.dismiss();
    }).catch(()=> {
      this.utils.showAlert('Error processing your data, please try again');
    }).finally(()=> {
      loading.dismiss();
    })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
      session: this.currentSession,
    });
  }
}
