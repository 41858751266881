import { Injectable, NgZone } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { StorageService } from '../storage/storage.service';
declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private api: ApiService,
    private localStorageService: StorageService,
    public ngZone: NgZone,
  ) {}

  async getGeoLocation(lat: number, lng: number): Promise<any> {
    let promise = new Promise( async(resolve, reject) => {
      if (navigator.geolocation) {
        let geocoder = await new google.maps.Geocoder();
        let latlng = await new google.maps.LatLng(lat, lng);
        let request = { latLng: latlng };
        await geocoder.geocode(request, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            let result = results[0];
            this.ngZone.run(() => {
              if (result != null) {
                resolve({
                  result: result,
                  coords:{
                    lat: lat,
                    lng: lng
                  }
                });
              } else {
                reject(false);
              }
            });
          } else {
            reject(false);
          }
        });
      } else {
        reject(false);
      }
    });
    return promise;
  }

  async showToast(message,duration = 2000){
    const toast = await  this.toastCtrl.create({
      message: message,
      duration: duration
    });
    toast.present();
  }

  async showAlert(message) {
    const alert = await  this.alertCtrl.create({
      mode:'ios',
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  getAppVersion(){
    return environment.version;
  }

  setLocalStorage(alias,value){
    localStorage.setItem(alias,value);
  }

  getLocalStorage(alias):Promise<any> {
    var promise = new Promise((resolve, reject)=>{
      var value = localStorage.getItem(alias);
      if(value){
        resolve(value);
      }else{
        reject(false);
      }
    });
    return promise;
  }

  distance(end,unit="Km") {
    let start = JSON.parse(localStorage.getItem('currentGPS'))
    if(!start) {
      start = {
        lat:28.8272832,
        lng:-111.93463
      };
    }
    if ((start.lat == end.lat) && (start.lng == start.lng)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * start.lat/180;
      var radlat2 = Math.PI * end.lat/180;
      var theta = start.lng-end.lng;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit=="K") { dist = dist * 1.609344 }
      if (unit=="N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  getUserData():any {
    let user = JSON.parse(localStorage.getItem('user')??'{}');
    return user;
  }
}
