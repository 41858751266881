import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, AlertController } from '@ionic/angular';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  constructor(
    private router: Router,
    private zone: NgZone,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) { }


  goTo(route: string, payload?: any) {
    // this.setNavigationParams(payload)
    this.zone.run(() => {
      this.router.navigateByUrl(route, payload);
    })
  }

  setNavigationParams(payload: any) {
    localStorage.setItem('routePayload', JSON.stringify(payload));
  }

  getNavigationParams(): any {
    return JSON.parse(localStorage.getItem('routePayload'));
  }

  async presentToast(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      message,
      duration: (duration) ? duration : 2000
    });
    toast.present();
  }

  async presentSimpleAlert(header: string, subHeader: string, message: string) {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [{
        text: 'OK',
        cssClass: 'btn btn-primary'
      }]
    });
    alert.present();
  }

  configureMatPaginator() {
    return {
      firstPageLabel: "Primera página",
      itemsPerPageLabel: "Registros por página",
      lastPageLabel: "Última página",
      nextPageLabel: "Página siguiente",
      previousPageLabel: "Página anterior",
      getRangeLabel: (page: number, pageSize: number, length: number) => {
        const start = page * pageSize + 1;
        const end = (page + 1) * pageSize;
        // return `${start} - ${end} de ${this.decimalPipe.transform(length)}`;
        return `${start} - ${end} de ${length}`;
      }
    }
  }

  getCurrentYear() {
    return moment().format('yyyy')
  }

  cleanTooltips() {
    while (document.getElementsByTagName('mat-tooltip-component').length > 0) { document.getElementsByTagName('mat-tooltip-component')[0].remove(); }
  }

}



