import { Injectable } from '@angular/core';
import { ApiService } from './../api/api.service';
import { AuthService } from './../auth/auth.service';
import { OperationsService } from 'src/app/services/operations/operations.service';
import { Source } from 'src/app/models/Source';

@Injectable({
  providedIn: 'root'
})
export class SourcesService {

  sources: Source[] = [];

  constructor (
    private _api: ApiService,
    private _auth: AuthService,
    private _operations: OperationsService
  ) { 
   
  }


fetchSources() {
  this._api.get('source/get').then((res:any)=> {
    this.sources = [];
    res.items.map((source:any) => {
      source.costLb = source.cost;
      this.sources.push(source);
    })
  })
}

  getSources() {
    return this.sources;
  }

  getSource(id: string) {
    
  }

}