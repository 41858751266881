import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Company } from 'src/app/models/Company';
import {
  ClientsService,
} from 'src/app/services/catalogs/clients.service';
import { MiscService } from 'src/app/services/misc/misc.service';
import {
  Session,
  SessionService,
} from 'src/app/services/sessions/session.service';

@Component({
  selector: 'app-new-session',
  templateUrl: './new-session.component.html',
  styleUrls: ['./new-session.component.scss'],
})
export class NewSessionComponent implements OnInit {
  currentSession: Session = null;
  clientCtrl: FormControl;
  descriptionCtrl: FormControl;
  clients: Company[] = [];
  clientsFiltered: Observable<any[]>;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _misc: MiscService,
    private _clients: ClientsService,
    private _sessions: SessionService,
    private modalCtrl: ModalController,
    private router: Router
  ) {
    this.form = this.fb.group({
      clientCtrl: ['', [Validators.required]],
      descriptionCtrl: [''],
    });
    this.clientCtrl = new FormControl();
    this.descriptionCtrl = new FormControl();

    this.clientsFiltered = this.clientCtrl.valueChanges.pipe(
      startWith(''),
      map((client) =>
        client ? this.filterClients(client) : this.clients.slice()
      )
    );
  }

  async ngOnInit() {
    this.clients = await this._clients.getClients();
  }

  filterClients(name: string) {
    return this.clients.filter(
      (cliente) => cliente.name.toLowerCase().indexOf(name) === 0
    );
  }

  gotoScenario() {
    if (!this.clientCtrl.value) {
      this._misc.presentSimpleAlert(
        'Wait a second...',
        '',
        'You need to select a client for this session'
      );
    } else {
      this.currentSession = {
        company: this.clientCtrl.value,
        description: this.descriptionCtrl.value,
      };
      this._sessions.setSession(this.currentSession);
      this.router.navigateByUrl('scenarios');
      this.dismiss();
    }
  }

  displayFn(item?: Company): string | undefined {
    // return item ? (item.nombreCorto + '   ' + item.conceptoNombre) : undefined;
    return item ? item.name : undefined;
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
      session: this.currentSession,
    });
  }
}
