import { NewContactComponent } from './../new-contact/new-contact.component';
import { NewCompanyComponent } from './../new-company/new-company.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { ClientsService } from 'src/app/services/catalogs/clients.service';
import { MiscService } from 'src/app/services/misc/misc.service';
import * as moment from 'moment';
import { EditContactComponent } from '../edit-contact/edit-contact.component';
import { Company } from 'src/app/models/Company';
import { Contact } from 'src/app/models/Contact';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Scenario } from 'src/app/models/Scenario';
import { ScenariosService } from 'src/app/services/scenarios/scenarios.service';
import { DeleteContactComponent } from '../delete-contact/delete-contact.component';
import { SourcesService } from 'src/app/services/catalogs/sources.service';


@Component({
  selector: 'app-new-scenario',
  templateUrl: './new-scenario.component.html',
  styleUrls: ['./new-scenario.component.scss'],
})
export class NewScenarioComponent implements OnInit {

  currentScenario: Scenario = null;
  clients: Company[] = [];
  clientsFiltered: Array<any> = [];
  public selectedCompany;
  selectedContact;
  contacts: Contact[]
  form: FormGroup;
  public titleFeedVolume = "Feed Volume";
  constructor(
    private fb: FormBuilder,
    private _misc: MiscService,
    private _clients: ClientsService,
    private _scenarios: ScenariosService,
    private _sources: SourcesService,
    private modalCtrl: ModalController,
    private router: Router,
    private loadingController: LoadingController,
    private api: ApiService,
    private storageService: StorageService
  ) {
    this.form = this.fb.group({
      company:     ['', Validators.required],
      contact:     ['', Validators.required],
      date:        [''],
      description: ['Testing..', Validators.required],
      comments:    ['']
    });
  }

  async ngOnInit() {
    let loading = await this.loadingController.create({
      message:'Updating data...',
      spinner:'dots',
      mode:'ios'
    });
    this._sources.fetchSources()

    loading.present();
      let companyMemory = JSON.parse(localStorage.getItem('currentCompany')??'{}')
      let adisseoNACompanyGuid = '28593914-7acd-eb11-9ffd-00155e011508'
      this._clients.getClients({ rowsPerPage:9999}).then((res:any)=> {
      this.clients =  res;
      this.clientsFiltered = res
      let company:any = this.clients.filter((companyItem)=> {return companyItem.companyId == companyMemory.companyId || companyItem.companyId == adisseoNACompanyGuid});
      if(company!=null) {
        if(company.length > 0) {
      this.form.get('company').patchValue(company[0]);
          this.selectedCompany = company[0];
          if(this.selectedCompany!=null) {
            this.api.get('contact/get',{companyId: this.selectedCompany.companyId}).then((res)=> {
              this.contacts = res.items;
              let contact = JSON.parse(localStorage.getItem('currentContact')??'{}');
              contact = this.contacts.filter((contactItem)=> { return contactItem.contactId == contact.contactId; });
              if(contact!=null) {
                this.selectedContact = contact;

                this.form.get('contact').patchValue(contact[0]);
              }
              if(this.contacts.length == 1) {
                this.selectedContact = this.contacts[0];
                this.storageService.setItem('currentContact', this.contacts[0]);
              }
            });
          }
        }
      }
      }).finally(()=> {
        loading.dismiss();
      });
    this.form.controls['date'].patchValue(moment().toDate());

  }

  filterClients(name: string) {
    if(typeof name === 'object') return;
    this.clientsFiltered = this.clients.filter((cliente) => cliente.name.toLowerCase().includes(name.toLowerCase()));
  }
  
  // filterClients(name:any) {
  //     name = name??'';
  //     if(typeof(name)!='object') {
  //       name = name.toLowerCase();
  //       this._clients.getClients({searchBy:name, rowsPerPage:9999}).then((res:any)=> {
  //         this.clients = res;
  //         this.clientsFiltered = res;
  //       }).catch(()=> {
  //         this.clients = [];
  //         this.clientsFiltered = [];
  //       })
  //     }
  // }

  async loadContacts(company) {
    this.selectedCompany = company;
    this.api.get('contact/get',{companyId: company.companyId}).then((res)=> {
      this.contacts = res.items;
      if(this.contacts.length==1) {
        this.selectedContact = this.contacts[0];
        this.storageService.setItem('currentContact', this.contacts[0]);
      }
      this.storageService.setItem('currentCompany',company);
    });
  }

  displayFn(item?: Company): string | undefined {
    return item ? item.name : undefined;
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
      session: this.currentScenario,
    });
  }

  async newCompany() {
    const modal = await this.modalCtrl.create({
      component: NewCompanyComponent,
      cssClass: 'modal-large',
      animated: true
    });
    modal.onDidDismiss().then(async (data: any) => {
      this.ngOnInit();
    });
    return await modal.present();
  }

  async newContact() {
    const modal = await this.modalCtrl.create({
      component: NewContactComponent,
      cssClass: 'modal-large',
      animated: true,
      componentProps: {
        company: this.selectedCompany,
      }
    });
    modal.onDidDismiss().then(async (data: any) => {
      this.ngOnInit()
    });
    return await modal.present();
  }

  setSelectedContact(contact:any) {
    this.selectedContact = contact.value;
    this.form.get('company').setValue(this.selectedCompany);
    this.form.get('contact').setValue(this.selectedContact);
  }

  async editContact() {
    const modal = await this.modalCtrl.create({
      component: EditContactComponent,
      cssClass: 'modal-large',
      animated: true,
      componentProps: {
        company:     this.selectedCompany,
        contactData: this.selectedContact
      }
    });
    modal.onDidDismiss().then((data: any) => {
      this.ngOnInit();
    });
    return await modal.present();
  }
  
  async deleteContact() {
    const modal = await this.modalCtrl.create({
      component: DeleteContactComponent,
      cssClass: 'modal-small',
      animated: true,
      componentProps: {
        company:     this.selectedCompany,
        contact: this.selectedContact
      }
    });
    modal.onDidDismiss().then((data: any) => {
      this.ngOnInit();
    });
    return await modal.present();
  }


  async gotoScenario() {
    let loading = await this.loadingController.create({
      message:'Creating new scenario...',
      spinner:'dots',
      animated:true,
      mode:'ios'
    });
    if (!this.form.controls['contact'].value) {
      this._misc.presentSimpleAlert('Wait a second...', '', 'You need to select a contact for this scenario');
    } else {
      this.currentScenario = {
        name: this.selectedContact.firstName + ' ' + this.selectedContact.lastName+' - '+this.selectedCompany.name,
        companyId: this.selectedCompany,
        contactId: this.form.controls['contact'].value,
        date: new Date(),
        description: this.form.controls['description'].value,
        comments: this.form.get('comments').value,
        optionOnePercent: 50.00,
      };
      loading.present();
      this.api.post('scenario/InsertOrUpdate', {
        name: this.selectedContact.firstName + ' ' + this.selectedContact.lastName+' - '+this.selectedCompany.name,
        companyId: this.currentScenario.companyId.companyId,
        contactId: this.currentScenario.contactId.contactId,
        date: this.currentScenario.date,
        description: this.currentScenario.description,
        comments: this.form.get('comments').value,
        optionOnePercent: 50.00,
      }).then(async(res:any) => {
        this.currentScenario.scenarioId = res.id;
        this.storageService.setItem('scenario',this.currentScenario);
        const scenario = await this._scenarios.setScenario(this.currentScenario);
        if (scenario) {
          this.storageService.setItem('newScenario',true);
          this.dismiss()
          this.router.navigateByUrl('scenario');
          loading.dismiss();
        } else {
          this._misc.presentSimpleAlert('Woops...', '', 'The scenario could not be created, please try again!');
        }
      }).finally(()=> loading.present());
    }
  }
}
