import { Component, OnInit, AfterViewInit, ViewChild, Input, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Router } from '@angular/router';

import { NewSessionComponent } from '../../modals/new-session/new-session.component';

import { AuthService } from './../../../services/auth/auth.service';
import { UiService } from './../../../services/ui/ui.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  @Input() sourceName: string = '';
  @Input() company:    string = '';
  @Input() scenario:   string = '';

  innerWidth = 0;
  url = '';

  constructor (
    private modalCtrl: ModalController,
    private router: Router,
    public _auth: AuthService,
    public _ui: UiService
  ) { }

  
  ngOnInit() {
    this.url = this.router.url;
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  async newSession() {
    const modal = await this.modalCtrl.create({
      component: NewSessionComponent,
      cssClass: 'modal-small',
      animated: true
    });
    
    modal.onDidDismiss().then((data) => {
      if (data) {
      }
    });

    return await modal.present();
  }

  toggleSidebar() {
    this._ui.toggleSidebar();
  }

}
