import { Injectable } from '@angular/core';
import { Scenario } from 'src/app/models/Scenario';
import { Source } from 'src/app/models/Source';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  readonly kgByMt: number = 1000.00;
  readonly gByKg: number = 1000.00;
  readonly lbsByKg: number = 2.20462;
  readonly mgByKg: number = 10000.00;
  readonly lbsByShortTon: number = 2000.00;

  constructor() { }

  //******  Cáculos para el Source , costo por kg, cost unit Se
  costKg(costLb: number): number {
    return costLb * this.lbsByKg;
  }

  costLb(costKg: number): number {
    return costKg / this.lbsByKg;
  }
  // Source.SeContent
  costUnitSe(pctSeContent: number, costLb: number): number {
    return costLb / pctSeContent;
  }

  pctSeAsSemet(pctSeContent: number, pctAvailableSeMet: number): number {
    return (pctSeContent * pctAvailableSeMet) / 100.00;
  }

  costUnitSeMet(costLb: number, pctSeContent: number, pctAvailableSeMet: number) {
    if(costLb === 0 || pctSeContent === 0 || pctAvailableSeMet === 0) {return 0;}
    return costLb / (1000 * (pctSeContent / 100 ) * (pctAvailableSeMet / 100.00));
  }

  ///       ******* Selenium Inclusion *********

  // Source.SeContent
  // Purity of Selenium; mg / kg
  puritySe(seContent: number) {
    return seContent * this.mgByKg;
  }

  // Addition rate of Selenium; g / MT
  additionRateSegMT(amountRequiredDiet: number, seContent: number): number {
    return (amountRequiredDiet * this.kgByMt * this.gByKg) / (seContent * this.mgByKg);
  }

  //Addition rate of Selenium; kg / MT
  additionRateSeKgMT(additionRateSegMT: number): number {
    return additionRateSegMT / this.gByKg;
  }

  // Addition rate of Selenium; g / ST
  additionRateSegST(additionRateSegMT: number): number {
    return (additionRateSegMT * this.lbsByShortTon) / (this.kgByMt * this.lbsByKg);
  }

  // Addition rate of Selenium; lb./ ST
  additionRateSeLbST(additionRateSegMT: number): number {
    return (additionRateSegMT * this.lbsByShortTon) / (this.kgByMt * this.gByKg);
  }

  // ******* Cost of[sourcename] *********


  // Cost Per treated Metric ton, $ / MT($ / tMT)
  costKgMt(costKg: number, additionRateSeKgMT: number) {
    return additionRateSeKgMT * costKg;
  }

  // Cost Per treated Short ton, $ / ST($ / tST)
  costLbSt(costKgMt: number,) {
    return ((costKgMt / this.kgByMt) / this.lbsByKg) * this.lbsByShortTon;
  }

  //      ********** Annual Feed and[sourcename]  tonnage

  // Feed volume, metric tons
  feedVolMT(feedVolSt: number, period: number): number {
    return ((((feedVolSt * this.lbsByShortTon) / this.lbsByKg)) / (this.kgByMt)) / period;
  }

  // Feed volume, short tons
  feedVolST(feedVolMt: number, period: number): number {
    return (((feedVolMt * this.lbsByShortTon) * this.lbsByKg) * this.kgByMt) / period;
  }

  //Potential Selenium Volumes, kg
  potSeVolKG(feedVolMT: number, additionRateSeKgMT: number, period: number) {
    return (feedVolMT * additionRateSeKgMT) / period;
  }

  // Potential Selenium Volumes, metric tons
  potSeVolMT(potSeVolKG: number, period: number): number {
    return (potSeVolKG / this.kgByMt) / period;
  }

  //Potential Selenium Volumes, lbs.
  potSeVolLbs(potSeVolMT: number, period: number): number {
    return (potSeVolMT * this.gByKg * this.lbsByKg) / period;
  }

  // Potential Selenium Volumes, short tons
  potSeVolST(potSeVolLbs: number, period: number): number {
    return (potSeVolLbs / this.lbsByShortTon) / period;
  }

  // Cost, USD
  costUsdLb(potSeVolLbs: number, costLb: number, period: number): number {
    return (potSeVolLbs * costLb) / period;
  }

  // Cost, USD metric
  costUsdMt(potSeVolKG: number, potSeVolMT: number, period: number): number {
    return (potSeVolKG * potSeVolMT) / period;
  }

  //Cost, USD / short tons
  costUsdShorTon(costUsdLb: number, feedVolST: number, period: number): number {
    return (costUsdLb / feedVolST) / period;
  }

  // Cost, USD / metric tons
  costUsdMetricTon(costUsdMt: number, feedVolMT: number, period: number) {
    return (costUsdMt * feedVolMT) / period;
  }


  calculateScenario(scenario: Scenario): Scenario {
    //source.seleniumInclusion.puritySe = this.puritySe(scenario.dataSource.source1.pctSeContent);
    //  scenario.seleniumInclusion.puritySe = this.puritySe(scenario.dataSource.source1.pctSeContent);
    return scenario;
  }

  calculateSource(source: Source, amountRequiredDiet: number, feedVolSt: number): Source {
    const costKg = this.costKg(source.costLb);
    const additionRateSegMt = this.additionRateSegMT(amountRequiredDiet, source.pctSeContent);
    const additionRateSeKgMT =  this.additionRateSeKgMT(additionRateSegMt);
    const costKgMt = this.costKgMt(costKg, additionRateSeKgMT);
    const feedVolMt =  this.feedVolMT(feedVolSt, 1.00);
    const potSeVolKG = this.potSeVolKG(feedVolMt, additionRateSeKgMT, 1.00);
    const potSeVolMT = this.potSeVolMT(potSeVolKG, 1.00);
    const potSeVolLbs = this.potSeVolLbs(potSeVolMT, 1.00);
    const costUsdLb = this.costUsdLb(potSeVolLbs, source.costLb, 1.00);
    const costUsdMt = this.costUsdMt(potSeVolKG, potSeVolMT, 1.00);

    const potSeVolKG4 = this.potSeVolKG(feedVolMt, additionRateSeKgMT, 4.00);
    const potSeVolMT4 = this.potSeVolMT(potSeVolKG, 4.00);


    const currentSource: Source = {
      sourceId: source.sourceId,
      id: source.id,
      name: source.name,
      pctSeContent: source.pctSeContent,
      pctAvailableSeMet: source.pctAvailableSeMet,
      costLb: source.costLb,
      amountRequiredDiet,
      seleniumInclusion: {
        puritySe: this.puritySe(source.pctSeContent),
        pctAmountSe: source.pctSeContent,
        pctAvailableSeMet: source.pctAvailableSeMet,
        additionRateSegMT: additionRateSegMt,
        additionRateSeKgMT,
        additionRateSeKgST: this.additionRateSegST(additionRateSegMt),
        additionRateSeLbST: this.additionRateSeLbST(additionRateSegMt)
      },
      costSource: {
        costKg,
        costKgMt,
        costLb: source.costLb,
        costLbSt: this.costLbSt(costKgMt),
       costUnitSeMetLb: this.costUnitSeMet(source.costLb,source.pctSeContent,source.pctAvailableSeMet),
      costUnitSeMetKg: this.costUnitSeMet(costKg,source.pctSeContent,source.pctAvailableSeMet)
    },
      annualFeedAndSourceTonnage: {
        feedVolMt,
        potSeVolKg: potSeVolKG,
        potSeVolMt: potSeVolMT,
        potSeVolLbs,
        potSeVolSt: this.potSeVolST(potSeVolLbs, 1.00),
        costUsdLb,
        costUsdShorTon: this.costUsdShorTon(costUsdLb, feedVolSt, 1.00),
        costUsdMt,
        costUsdMetricTon: this.costUsdMetricTon(costUsdMt, feedVolMt, 1.00),
        costKgMt,
        costLbSt: this.costLbSt(costKgMt)
      },
      quaterlyFeedAndSourceTonnage: {
        feedVolSt: feedVolSt / 4.00,
        feedVolMt: this.feedVolMT(feedVolSt, 4.00),
        potSeVolKg: potSeVolKG4,
        potSeVolMt: potSeVolMT4,
        potSeVolLbs: this.potSeVolLbs(potSeVolMT, 4.00),
        potSeVolSt: this.potSeVolST(potSeVolLbs, 4.00),
        costUsdLb: this.costUsdLb(potSeVolLbs, source.costLb, 4.00),
        costUsdMt: this.costUsdMt(potSeVolKG4, potSeVolMT4, 4.00),
        costKgMt: costKgMt / 4.00,
        costLbSt: this.costLbSt(costKgMt) / 4.00
      }

    };
    return currentSource;
  }

}

