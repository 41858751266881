import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Scenario } from 'src/app/models/Scenario';
const SELISSEO_SCENARIO = "selisseo_scenario";

@Injectable({
  providedIn: 'root'
})
export class ScenariosService {


  private scenarioSubject = new BehaviorSubject<Scenario>(null);
  readonly scenario$: Observable<Scenario> = this.scenarioSubject.asObservable();

  constructor() {
    if (localStorage.getItem(SELISSEO_SCENARIO)) {
      this.scenarioSubject.next(JSON.parse(localStorage.getItem(SELISSEO_SCENARIO)));
    }
  }


  setScenario(scenario: Scenario) {
    return new Promise((resolve, reject) => {
      localStorage.setItem(SELISSEO_SCENARIO, JSON.stringify(scenario));
      this.scenarioSubject.next(scenario);
      resolve(scenario);
    })

  }

  deleteScenario() {
    localStorage.removeItem(SELISSEO_SCENARIO);
  }


}
