import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})

export class ForgotPasswordComponent implements OnInit {
 
  forgotPasswordForm: FormGroup;
    constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private api: ApiService,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.forgotPasswordForm = this.fb.group({
      userName   : ['', Validators.required],
      applicationId:[environment.appID],
      language:['en-US'],

    });
  }

  ngOnInit() {
  }

  async forgotPassword() {

    let loading = await this.loadingController.create({
      message:'Sending',
      mode:'ios',
      spinner:'dots'
    });
    loading.present();
    this.api.security_post('recoveryPassword',this.forgotPasswordForm.value).then((res) => {
      this.utils.showToast('Email sent successfully!');
      this.dismiss();
    }).catch(()=> {
      this.utils.showAlert('The username or email does not exist.');
    }).finally(()=> {
      loading.dismiss();
    })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      comparison: true,
    });
  }

}
