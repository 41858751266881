export const server = {
  base_url: 'https://api.mrcodigo.com',
  // base_url: 'https://localhost:44306/',
  base_url_security:'https://security.mrcodigo.com/api/security'
}

export const environment = {
  production: true,
  url: server.base_url + '/api/selisseo',
  // urlInternet: server.internet_url + '/api/v1',
  // photos: server.files_url + '/usuarios',
  appID:'C1F3A7D7-709D-EB11-A008-00155E00F00B',
  version: [
    { id: '0.0.1', date: '2021-03-01', action: 'FIRST COMMIT', description: 'INIT: Se creó la estructura de la app.' }
  ]
};
