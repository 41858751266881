import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UiService } from 'src/app/services/ui/ui.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  public isOpen: boolean = false;

  constructor(
    public _ui: UiService,
    private storageService: StorageService,
    private auth: AuthService,
    private navController: NavController
  ) { }

  ngOnInit() {}
  hideMenu() {
    this._ui.closeSidebar();
  }
  logout() {
    this.hideMenu();
    this.storageService.removeItem('token');
    this.navController.navigateRoot('login');
    this.auth.logout();
  }
}
