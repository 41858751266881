import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Scenario } from 'src/app/models/Scenario';
import { Source } from 'src/app/models/Source';
import { ScenariosService } from 'src/app/services/scenarios/scenarios.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import JSPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { Printer, PrintOptions } from '@ionic-native/printer/ngx';
import { MiscService } from 'src/app/services/misc/misc.service';
import { ApiService } from 'src/app/services/api/api.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-print-scenario',
  templateUrl: './print-scenario.component.html',
  styleUrls: ['./print-scenario.component.scss'],
})
export class PrintScenarioComponent implements OnInit {
  summary = [
    {
      item: 'annualCostUsd',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'quaterlyCostUsd',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'deltaCostUsd',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'SeMsumProgramCostUsdST',
      data:[
        {
          icon: 'S1',
          total: 0
        },
        {
          icon: 'S2',
          total:0
        },
        {
          icon: 'S3',
          total:0
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0
        }
      ],
      minValue: 0
    },
    {
      item: 'SeMsumProgramCostUsdMT',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'deltaCostUsdST',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'potSeVolMT',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'potSeVolST',
      data: [
        {
          icon: 'S1',
          total: 0,
        },
        {
          icon: 'S2',
          total:0,
        },
        {
          icon: 'S3',
          total:0,
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0,
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0,
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0,
        }
      ],
      minValue: 0,
    },
    {
      item: 'SeMet',
      data:[
        {
          icon: 'S1',
          total: 0
        },
        {
          icon: 'S2',
          total:0
        },
        {
          icon: 'S3',
          total:0
        },
        {
          icon: '1/2 S1 & 1/2 S2',
          total: 0
        },
        {
          icon: '1/2 S1 & 1/2 S3',
          total: 0
        },
        {
          icon: 'EqS 1/2 S1 & 1/2 S2',
          total: 0
        }
      ],
      minValue: 0
    }
  ];

  backgroundColors: string[] = [];

  source1: Source = {
    id: 0,
    name: '',
    pctSeContent: 0.0,
    pctAvailableSeMet: 0.0,
    costLb: 0.05,
    seleniumInclusion: {
      puritySe: 0.0,
      pctAmountSe: 0.0,
      pctAvailableSeMet: 0.0,
      additionRateSeKgMT: 0.0,
      additionRateSegMT: 0.0,
      additionRateSeKgST: 0.0,
      additionRateSeLbST: 0.0,
    },
    costSource: {
      costKg: 0.0,
      costKgMt: 0.0,
      costLb: 0.0,
      costLbSt: 0.0,
    },
    annualFeedAndSourceTonnage: {
      feedVolMt: 0.0,
      potSeVolKg: 0.0,
      potSeVolMt: 0.0,
      potSeVolLbs: 0.0,
      potSeVolSt: 0.0,
      costUsdLb: 0.0,
      costUsdMt: 0.0,
      costUsdShorTon: 0.0,
      costUsdMetricTon: 0.0,
      costKgMt: 0.0,
      costLbSt: 0.0,
    },
    quaterlyFeedAndSourceTonnage: {
      feedVolSt: 0.0,
      feedVolMt: 0.0,
      potSeVolKg: 0.0,
      potSeVolMt: 0.0,
      potSeVolLbs: 0.0,
      potSeVolSt: 0.0,
      costUsdLb: 0.0,
      costUsdMt: 0.0,
      costKgMt: 0.0,
      costLbSt: 0.0,
    },
  };

  currentScenario: Scenario = {
    name: '',
    companyId: 0,
    contactId: 0,
    date: new Date(),
    description: '',
    optionOnePercent: 0,
    dataSource: {
      source1: this.source1,
      source2: this.source1,
      source3: this.source1,
      halfSource1: this.source1,
      halfSource2: this.source1,
      halfSource3: this.source1,
      thirdSource1: this.source1,
      twoThirdSource2: this.source1,
      twoThirdSource3: this.source1,
      amountRequiredDiet: 0.0,
      feedVolSt: 0,
      measure: {
        id: 'i',
        name: 'imperial',
      },
    },
  };

  s2AndS1: string;
  s3AndS1: string;
  eqS2AndS1: string;

  blend1S3: string;
  blend1S1: string;
  blend2S2: string;
  blend2S1: string;
  blend3S2: string;
  blend3S1: string;

  
  ssAmountRequiredDietFormatted: string;
  syAmountRequiredDietFormatted: string;
  soAmountRequiredDietFormatted: string;


  loading: any;
  dataUser:any;

  btnDownloaderVisible = true;
  printMode = 1;
  isTablet :boolean = false;
  currentParameter: any;

  constructor(
    private modalCtrl: ModalController,
    private _scenarios: ScenariosService,
    private loadingController: LoadingController,
    private storageService: StorageService,
    private printer: Printer,
    private platform: Platform,
    public  _misc: MiscService,
    private api: ApiService,
    private utils: UtilsService
  ) {
  }

  ngOnInit() {
    this.isTablet = this.platform.is('ipad') || this.platform.is('tablet');

    this.currentParameter = this.storageService.getItem('param').value


    this._scenarios.scenario$.subscribe((scenario) => {
      this.currentScenario = scenario;
      this.SetHeaderNames();

      this.SetTableValues(this.currentScenario);
    });
    this.storageService.getItem('user').subscribe((user)=> {
      this.dataUser = user;
    });
  }

   SetHeaderNames() {
    const equivalentPercent = Math.round((100 * this.currentScenario.dataSource.equivalentHalfSource2.amountRequiredDiet / this.currentScenario.dataSource.amountRequiredDiet));
    const roundedOptionOnePercentMinus100 = Math.round(Math.round((100 - this.currentScenario.optionOnePercent)));
    const roundedEquivalentPercentMinus100 = Math.round(100 - equivalentPercent);



 
    this.ssAmountRequiredDietFormatted = Number(this.currentScenario.dataSource?.source1.amountRequiredDiet * (this.currentScenario.dataSource?.source1.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3);
    this.syAmountRequiredDietFormatted =  Number(this.currentScenario.dataSource?.source3.amountRequiredDiet * (this.currentScenario.dataSource?.source3.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3);
    this.soAmountRequiredDietFormatted =  Number(this.currentScenario.dataSource?.source2.amountRequiredDiet * (this.currentScenario.dataSource?.source2.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3);
 


    this.blend1S1 = roundedOptionOnePercentMinus100 +'% of Se is from '+this.currentScenario.dataSource?.source1.name;
    this.blend1S3 = this.currentScenario.optionOnePercent +'% of Se is from '+this.currentScenario.dataSource?.source3.name;
    this.blend2S1 = roundedOptionOnePercentMinus100 +'% of Se is from '+this.currentScenario.dataSource?.source1.name;
    this.blend2S2 = this.currentScenario.optionOnePercent +'% of Se is from '+this.currentScenario.dataSource?.source2.name;
    this.blend3S1 = roundedEquivalentPercentMinus100+'% of Se is from '+this.currentScenario.dataSource?.source1.name;
    this.blend3S2 =  equivalentPercent +'% of Se is from '+this.currentScenario.dataSource?.source2.name;




    this.s3AndS1 = this.currentScenario.optionOnePercent +'% SY & '+ roundedOptionOnePercentMinus100+'% SS';
    this.s2AndS1 = this.currentScenario.optionOnePercent +'% SO & '+ roundedOptionOnePercentMinus100  +'% SS';
    this.eqS2AndS1 = 'Eq SeMet '+equivalentPercent  +'% SO & ' + roundedEquivalentPercentMinus100+'% SS';

  }

  SetTableValues(currentScenario: Scenario) {
    this.summary[0].data[0].total = currentScenario.dataSource?.source1.annualFeedAndSourceTonnage?.costUsdLb;
    this.summary[0].data[1].total = currentScenario.dataSource?.source3.annualFeedAndSourceTonnage?.costUsdLb;
    this.summary[0].data[2].total = currentScenario.dataSource?.source2.annualFeedAndSourceTonnage?.costUsdLb;
    this.summary[0].data[3].total = (currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource3.annualFeedAndSourceTonnage?.costUsdLb);
    this.summary[0].data[4].total = (currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource2.annualFeedAndSourceTonnage?.costUsdLb);
    this.summary[0].data[5].total = (currentScenario.dataSource?.equivalentHalfSource1.annualFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.equivalentHalfSource2.annualFeedAndSourceTonnage?.costUsdLb);

    this.summary[0].minValue = Math.min.apply(null, this.summary[0].data.map(item => item.total));



    // Quarterly Cost, USD
    this.summary[1].data[0].total = currentScenario.dataSource?.source1.quaterlyFeedAndSourceTonnage?.costUsdLb;
    this.summary[1].data[1].total = currentScenario.dataSource?.source3.quaterlyFeedAndSourceTonnage?.costUsdLb;
    this.summary[1].data[2].total = currentScenario.dataSource?.source2.quaterlyFeedAndSourceTonnage?.costUsdLb;
    this.summary[1].data[3].total = (currentScenario.dataSource?.halfSource1.quaterlyFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource3.quaterlyFeedAndSourceTonnage?.costUsdLb);
    this.summary[1].data[4].total = (currentScenario.dataSource?.halfSource1.quaterlyFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.halfSource2.quaterlyFeedAndSourceTonnage?.costUsdLb);
    this.summary[1].data[5].total = (currentScenario.dataSource?.equivalentHalfSource1.quaterlyFeedAndSourceTonnage?.costUsdLb + currentScenario.dataSource?.equivalentHalfSource2.quaterlyFeedAndSourceTonnage?.costUsdLb);

    this.summary[1].minValue = Math.min.apply(null, this.summary[1].data.map(item => item.total));




    //Delta vs. 100% Selisseo, USD
    this.summary[2].data[0].total = this.summary[0].data[2].total - this.summary[0].data[0].total;
    this.summary[2].data[1].total = this.summary[0].data[2].total - this.summary[0].data[1].total;
    // this.summary[2].data[2].total =  this is 100% selisseo .. no point in doing calculation;
    this.summary[2].data[3].total = this.summary[0].data[2].total - this.summary[0].data[3].total;
    this.summary[2].data[4].total = this.summary[0].data[2].total - this.summary[0].data[4].total;
    this.summary[2].data[5].total = this.summary[0].data[2].total - this.summary[0].data[5].total;

    this.summary[2].minValue = Math.max.apply(null, (this.summary[2].data.filter((item) => { return item.total > 0; }).map(item => item.total)));


    // Cost Per treated Short ton, $/ ST
    this.summary[3].data[0].total = currentScenario.dataSource?.source1.costSource?.costLbSt;
    this.summary[3].data[1].total = currentScenario.dataSource?.source3.costSource?.costLbSt;
    this.summary[3].data[2].total = currentScenario.dataSource?.source2.costSource?.costLbSt;
    this.summary[3].data[3].total = (currentScenario.dataSource?.halfSource1.costSource?.costLbSt + currentScenario.dataSource?.halfSource3.costSource?.costLbSt);
    this.summary[3].data[4].total = (currentScenario.dataSource?.halfSource1.costSource?.costLbSt + currentScenario.dataSource?.halfSource2.costSource?.costLbSt);
    this.summary[3].data[5].total =  (currentScenario.dataSource?.equivalentHalfSource1.costSource?.costLbSt + currentScenario.dataSource?.equivalentHalfSource2.costSource?.costLbSt);

    this.summary[3].minValue = Math.min.apply(null, this.summary[3].data.map(item => item.total));

    // Cost Per treated Metric ton, $/ MT

    this.summary[4].data[0].total =  currentScenario.dataSource?.source1.costSource?.costKgMt;
    this.summary[4].data[1].total =  currentScenario.dataSource?.source3.costSource?.costKgMt;
    this.summary[4].data[2].total =  currentScenario.dataSource?.source2.costSource?.costKgMt;
    this.summary[4].data[3].total =  (currentScenario.dataSource?.halfSource1.costSource?.costKgMt + currentScenario.dataSource?.halfSource3.costSource?.costKgMt);
    this.summary[4].data[4].total =  (currentScenario.dataSource?.halfSource1.costSource?.costKgMt + currentScenario.dataSource?.halfSource2.costSource?.costKgMt);
    this.summary[4].data[5].total =   (currentScenario.dataSource?.equivalentHalfSource1.costSource?.costKgMt + currentScenario.dataSource?.equivalentHalfSource2.costSource?.costKgMt);

    this.summary[4].minValue = Math.min.apply(null, this.summary[4].data.map(item => item.total));

    //Delta vs. 100% Selisseo, USD ST
    this.summary[5].data[0].total = this.summary[3].data[2].total - this.summary[3].data[0].total;
    this.summary[5].data[1].total = this.summary[3].data[2].total - this.summary[3].data[1].total;
    // this.summary[3].data[2].total =  this is 100% selisseo .. no point in doing calculation;
    this.summary[5].data[3].total = this.summary[3].data[2].total - this.summary[3].data[3].total;
    this.summary[5].data[4].total = this.summary[3].data[2].total - this.summary[3].data[4].total;
    this.summary[5].data[5].total = this.summary[3].data[2].total - this.summary[3].data[5].total;

    this.summary[5].minValue = Math.max.apply(null, (this.summary[5].data.filter((item) => { return item.total > 0; }).map(item => item.total)));


    //Potential Org.Selenium volumes, metric ton
    this.summary[6].data[0].total = currentScenario.dataSource?.source1.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[1].total = currentScenario.dataSource?.source3.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[2].total = currentScenario.dataSource?.source2.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[3].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolMt + currentScenario.dataSource?.halfSource3.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[4].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolMt + currentScenario.dataSource?.halfSource2.annualFeedAndSourceTonnage?.potSeVolMt;
    this.summary[6].data[5].total = currentScenario.dataSource?.equivalentHalfSource1.annualFeedAndSourceTonnage?.potSeVolMt + currentScenario.dataSource?.equivalentHalfSource2.annualFeedAndSourceTonnage?.potSeVolMt;

    this.summary[6].minValue = Math.min.apply(null, this.summary[6].data.map(item => item.total));

    //Potential Org.Selenium volumes, short ton
    this.summary[7].data[0].total = currentScenario.dataSource?.source1.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[1].total = currentScenario.dataSource?.source3.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[2].total = currentScenario.dataSource?.source2.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[3].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolSt + currentScenario.dataSource?.halfSource3.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[4].total = currentScenario.dataSource?.halfSource1.annualFeedAndSourceTonnage?.potSeVolSt + currentScenario.dataSource?.halfSource2.annualFeedAndSourceTonnage?.potSeVolSt;
    this.summary[7].data[5].total = currentScenario.dataSource?.equivalentHalfSource1.annualFeedAndSourceTonnage?.potSeVolSt + currentScenario.dataSource?.equivalentHalfSource2.annualFeedAndSourceTonnage?.potSeVolSt;


    this.summary[7].minValue = Math.min.apply(null, this.summary[7].data.map(item => item.total));

    //SeMet $/Lb
    this.summary[8].data[0].total = (currentScenario.dataSource?.source1.costSource?.costUnitSeMetLb);
    this.summary[8].data[1].total = (currentScenario.dataSource?.source3.costSource?.costUnitSeMetLb);
    this.summary[8].data[2].total = (currentScenario.dataSource?.source2.costSource?.costUnitSeMetLb);
    this.summary[8].data[3].total = (currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb != 0 ? currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb : 0) + currentScenario.dataSource?.halfSource3.costSource?.costUnitSeMetLb;

    this.summary[8].data[4].total = (currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb != 0 ? currentScenario.dataSource?.halfSource1.costSource?.costUnitSeMetLb : 0) + currentScenario.dataSource?.halfSource2.costSource?.costUnitSeMetLb;
    this.summary[8].data[5].total = (currentScenario.dataSource?.equivalentHalfSource1.costSource?.costUnitSeMetLb != 0 ? currentScenario.dataSource?.equivalentHalfSource1.costSource?.costUnitSeMetLb : 0) + currentScenario.dataSource?.equivalentHalfSource2.costSource?.costUnitSeMetLb;

    this.summary[8].minValue = Math.min.apply(null, (this.summary[8].data.filter((item) => { return item.total > 0; }).map(item => item.total)));
  }


  async printScenario() {
      let loading = await this.loadingController.create({
            message:'Generating...',
            animated:true,
            spinner:'dots',
            mode:'ios'
      });
      try {
              this.btnDownloaderVisible = false;
              loading.present();
              var node = document.getElementById('pdfContainer');
              var img;
    var currentdate = new Date();
    var filename = this.currentScenario.companyId.name + "-" +currentdate.getDate() + "-" + (currentdate.getMonth()+1)  + "-" + currentdate.getFullYear() ;
    var newImage;
              domtoimage.toPng(node, { bgcolor: '#fff', height:1024 })
                .then((dataUrl)  => {
                  img = new Image();
                  img.src = dataUrl;
                  newImage = img.src;
                  if(this.isTablet && this.currentParameter != "kiosk") {
                    let options: PrintOptions = {
                      name: 'Selisseo Simulator',
                      duplex: true,
                      orientation: 'landscape',
                      monochrome:false
                      }
                      newImage = newImage.replace('data:image/png;base64,','base64://');
                      this.printer.print(newImage, options).then(()=> {
                      }, (err:any)=> {
                      });
                  } else {
                    img.onload = function () {
                      var pdfWidth = img.width;
                      var pdfHeight = img.height;
                      var doc;
                      if(pdfWidth > pdfHeight)
                      {
                        doc = new JSPDF('l', 'px', [pdfWidth  , pdfHeight]);
                      }
                      else
                      {
                        doc = new JSPDF('p', 'px', [pdfWidth , pdfHeight]);
                      }
                      var width = doc.internal.pageSize.getWidth();
                      var height = doc.internal.pageSize.getHeight();
                      doc.addImage(newImage, 'PNG',  10, 10, width, height);
                      doc.save(filename);
                      loading.dismiss();
                    }
                  }
                })
                .catch((error) =>{}).finally(()=> {
                  loading.dismiss();
                  this.btnDownloaderVisible = true;
                });
      } catch(e) {
        loading.dismiss();
        this.utils.showAlert('Unable to process request, please try again');
      }
  }

  async sendMailScenario() {
    let loading = await this.loadingController.create({
      message:'Sending...',
      animated:true,
      mode:'ios'
    });
    loading.present();
    try {
      this.api.post('scenario/quote',{
        "UserName": this.dataUser.firstName+' '+this.dataUser.lastName,
        "Description": (this.currentScenario.description!=null?(this.currentScenario.description.substr(0,57)+'...'):''),
        "ContactName": this.currentScenario.contactId!=null?this.currentScenario.contactId.titleViewModel.name+'/'+this.currentScenario.contactId.firstName+'/'+this.currentScenario.contactId.lastName:'',
        "CompanyName": this.currentScenario.contactId!=null?this.currentScenario.companyId.name:'',
        "CreationDate": this.currentScenario.date,
        "ContactEmail": "hola@mrcodigo.com",
        "OptionOnePercent": this.currentScenario.optionOnePercent,
        "S2AndS1": this.s2AndS1,
        "S3AndS1": this.s3AndS1,
        "EqS2AndS1": this.eqS2AndS1,
        "Annual": {
            "Source1": this.roundNumber(this.summary[0].data[0].total,0),
            "Source2": this.roundNumber(this.summary[0].data[1].total,0),
            "Source3": this.roundNumber(this.summary[0].data[2].total,0),
            "CustomerOneHalfS1HalfS2": this.roundNumber(this.summary[0].data[3].total,0),
            "CustomerOneHalfS1HalfS3": this.roundNumber(this.summary[0].data[4].total,0),
            "EqSemOneHalfS1OneHalfS2": this.roundNumber(this.summary[0].data[5].total,0),
            "MinValue": this.roundNumber(this.summary[0].minValue,0)
        },
        "Quarterly": {
            "Source1": this.roundNumber(this.summary[1].data[0].total,0),
            "Source2": this.roundNumber(this.summary[1].data[1].total,0),
            "Source3": this.roundNumber(this.summary[1].data[2].total,0),
            "CustomerOneHalfS1HalfS2": this.roundNumber(this.summary[1].data[3].total,0),
            "CustomerOneHalfS1HalfS3": this.roundNumber(this.summary[1].data[4].total,0),
            "EqSemOneHalfS1OneHalfS2": this.roundNumber(this.summary[1].data[5].total,0),
            "MinValue": this.roundNumber(this.summary[1].minValue,4)
        },
        "DeltaVsHundredPercentUsd": {
            "Source1": this.roundNumber(this.summary[2].data[0].total,0),
            "Source2": this.roundNumber(this.summary[2].data[1].total,0),
            "Source3": "-",
            "CustomerOneHalfS1HalfS2": this.roundNumber(this.summary[2].data[3].total,0),
            "CustomerOneHalfS1HalfS3": this.roundNumber(this.summary[2].data[4].total,0),
            "EqSemOneHalfS1OneHalfS2": this.roundNumber(this.summary[2].data[5].total,0),
            "MinValue": this.roundNumber(this.summary[2].minValue,0)
        },
        "CostPerTreatedSt": {
            "Source1": this.roundNumber(this.summary[3].data[0].total,2),
            "Source2": this.roundNumber(this.summary[3].data[1].total,2),
            "Source3": this.roundNumber(this.summary[3].data[2].total,2),
            "CustomerOneHalfS1HalfS2":this.roundNumber(this.summary[3].data[3].total,2),
            "CustomerOneHalfS1HalfS3":this.roundNumber(this.summary[3].data[4].total,2),
            "EqSemOneHalfS1OneHalfS2":this.roundNumber(this.summary[3].data[5].total,2),
            "MinValue": this.roundNumber(this.summary[3].minValue,2)
        },
        "CostPerTreatedMt": {
          "Source1": this.roundNumber(this.summary[4].data[0].total,2),
          "Source2": this.roundNumber(this.summary[4].data[1].total,2),
          "Source3": this.roundNumber(this.summary[4].data[2].total,2),
          "CustomerOneHalfS1HalfS2":this.roundNumber(this.summary[4].data[3].total,2),
          "CustomerOneHalfS1HalfS3":this.roundNumber(this.summary[4].data[4].total,2),
          "EqSemOneHalfS1OneHalfS2":this.roundNumber(this.summary[4].data[5].total,2),
          "MinValue": this.roundNumber(this.summary[4].minValue,2)
      },
        "DeltaVsHundredPercentUsdSt": {
            "Source1": this.roundNumber(this.summary[5].data[0].total,2),
            "Source2": this.roundNumber(this.summary[5].data[1].total,2),
            "Source3": "-",
            "CustomerOneHalfS1HalfS2": this.roundNumber(this.summary[5].data[3].total,2),
            "CustomerOneHalfS1HalfS3": this.roundNumber(this.summary[5].data[4].total,2),
            "EqSemOneHalfS1OneHalfS2": this.roundNumber(this.summary[5].data[5].total,2),
            "MinValue": this.roundNumber(this.summary[5].minValue,2)
        },
        "PotentialOrgSeleniumVolumesMetric": {
            "Source1":  this.roundNumber(this.summary[6].data[0].total,2),
            "Source2":  this.roundNumber(this.summary[6].data[1].total,2),
            "Source3":  this.roundNumber(this.summary[6].data[2].total,2),
            "CustomerOneHalfS1HalfS2":this.roundNumber(this.summary[6].data[3].total,2),
            "CustomerOneHalfS1HalfS3":this.roundNumber(this.summary[6].data[4].total,2),
            "EqSemOneHalfS1OneHalfS2":this.roundNumber(this.summary[6].data[5].total,2),
            "MinValue":this.roundNumber(this.summary[6].minValue,2)
        },
        "PotentialOrgSeleniumVolumesShort": {
            "Source1":  this.roundNumber(this.summary[7].data[0].total,2),
            "Source2":  this.roundNumber(this.summary[7].data[1].total,2),
            "Source3":  this.roundNumber(this.summary[7].data[2].total,2),

            "CustomerOneHalfS1HalfS2": this.roundNumber(this.summary[7].data[3].total,2),
            "CustomerOneHalfS1HalfS3": this.roundNumber(this.summary[7].data[4].total,2),
            "EqSemOneHalfS1OneHalfS2": this.roundNumber(this.summary[7].data[5].total,2),
            "MinValue": this.roundNumber(this.summary[7].minValue,2)
        },
        "CostUnitSeMet": {
          "Source1":  "-",
          "Source2":  this.roundNumber(this.summary[8].data[1].total,0),
          "Source3":  this.roundNumber(this.summary[8].data[2].total,0),

          "CustomerOneHalfS1HalfS2": this.roundNumber(this.summary[8].data[3].total,0),
          "CustomerOneHalfS1HalfS3": this.roundNumber(this.summary[8].data[4].total,0),
          "EqSemOneHalfS1OneHalfS2": this.roundNumber(this.summary[8].data[5].total,0),
          "MinValue": this.roundNumber(this.summary[8].minValue,0)
      },
        "CreatedBy": this.currentScenario.createdBy,
        //New table parameters
        "Source1Name": this.currentScenario.dataSource?.source1.name,
        "Source2Name": this.currentScenario.dataSource?.source2.name,
        "Source3Name": this.currentScenario.dataSource?.source3.name,
        "Blend1S1": this.blend1S1,
        "Blend1S3": this.blend1S3,
        "Blend2S1": this.blend2S1,
        "Blend2S2": this.blend2S2,
        "Blend3S1": this.blend3S1,
        "Blend3S2": this.blend3S2,
        "SeMetSS": Number(this.currentScenario.dataSource?.source1.amountRequiredDiet * (this.currentScenario.dataSource?.source1.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3),
        "SeMetSY": Number(this.currentScenario.dataSource?.source3.amountRequiredDiet * (this.currentScenario.dataSource?.source3.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3),
        "SeMetSO": Number(this.currentScenario.dataSource?.source2.amountRequiredDiet * (this.currentScenario.dataSource?.source2.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3),
        "SeMetBlend1": Number(this.currentScenario.dataSource?.halfSource3.amountRequiredDiet * (this.currentScenario.dataSource?.halfSource3.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3),
        "SeMetBlend2": Number(this.currentScenario.dataSource?.halfSource2.amountRequiredDiet * (this.currentScenario.dataSource?.halfSource2.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3),
        "SeMetBlend3": Number(this.currentScenario.dataSource?.equivalentHalfSource2.amountRequiredDiet * (this.currentScenario.dataSource?.equivalentHalfSource2.seleniumInclusion?.pctAvailableSeMet / 100)).toFixed(3),
        "SeMetGramSS": "-",
        "SeMetGramSY": "$" + Number(this.summary[8].data[1].total).toFixed(2),
        "SeMetGramSO": "$" +  Number(this.summary[8].data[2].total).toFixed(2),
        "SeMetGramBlend1": "$" + Number(this.summary[8].data[3].total).toFixed(2),
        "SeMetGramBlend2": "$" + Number(this.summary[8].data[4].total).toFixed(2),
        "SeMetGramBlend3": "$" + Number(this.summary[8].data[5].total).toFixed(2),
        "TotalCostSS": "$" + Number(this.summary[3].data[0].total).toFixed(2),
        "TotalCostSY": "$" + Number(this.summary[3].data[1].total).toFixed(2),
        "TotalCostSO": "$" + Number(this.summary[3].data[2].total).toFixed(2),
        "TotalCostBlend1": "$" + Number(this.summary[3].data[3].total).toFixed(2),
        "TotalCostBlend2": "$" + Number(this.summary[3].data[4].total).toFixed(2),
        "TotalCostBlend3": "$" + Number(this.summary[3].data[5].total).toFixed(2)
      }).then(()=> {
        this.utils.showAlert('E-mail sent successfully');
      }).catch(()=> {
        this.utils.showAlert('It has not been possible to send email, please try again');
      }).finally(()=> { loading.dismiss()});
    } catch(e) {
      loading.dismiss()
      this.utils.showAlert('Unable to process request, please try again');
    }
  }
  roundNumber(num:any,fixed) {
    let v = (""+num).split('.');
    if(v[1]) {
      if(parseFloat('0.'+v[1])>0.0001) {
        num = parseFloat(num).toFixed(fixed);
      } else {
        num = parseFloat(v[0]);
      }
    }
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }

  dismiss() {
    this.modalCtrl.dismiss({});
  }
}
