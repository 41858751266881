import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { environment, server } from 'src/environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  apiUrl = environment.url;
  apiSecurity = server.base_url_security
  constructor(
    public http: HttpClient,
    public alert: AlertController,
    private storage: Storage
  ) { }

  get(endpoint: string, params:any=null):Promise<any> {
    const url = this.apiUrl + '/' + endpoint;
    this.http.get(url);
    return new Promise((resolve, reject) => {
      return this.http.get(url,{params:params}).subscribe(res => {
        this.storage.set(endpoint, res);
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

  post(endpoint: string, params?: any) {
   const url = this.apiUrl + '/' + endpoint;
    return new Promise((resolve, reject) => {
      return this.http.post(url, params).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

  put(endpoint: string, params?: any) {
    const url = this.apiUrl + '/' + endpoint;
    return new Promise((resolve, reject) => {
      return this.http.put(url, params).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

  delete(endpoint: string, params?: any) {
    const url = this.apiUrl + '/' + endpoint;
    return new Promise((resolve, reject) => {
      return this.http.delete(url, params).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

  security_get(url): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      responseType: 'text' as 'json'
    };
    const promise = new Promise((resolve, reject) => {
      this.http.get(this.apiSecurity+'/'+url,httpOptions).subscribe(data => {
        resolve(data)
      },error=>{
        reject(error);
      });
    })
    return promise;
  }

  security_post(url, data) {
    const promise = new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type':  'application/json' }),
        responseType: 'text' as 'json'
      };
      this.http.post(this.apiSecurity+'/'+url, data, httpOptions).subscribe(data => {
        resolve(data)
      },error=>{
        reject(error);
      });
    })
    return promise;
  }
}
